import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Card,
  Checkbox,
  DataPoint,
  Dropdown,
  LoadingAnimation,
} from "app/components";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserConfiguration,
  getUserSchema,
  updateUserConfiguration,
} from "app/store/actions/user";
import {
  userConfigurationLoadingSelector,
  userConfigurationSelector,
  userSchemaLoadingSelector,
  userSchemaSelector,
} from "app/store/selectors/user";
import "./index.scss";

const NotificationsCard = forwardRef(({ userId, editMode }, ref) => {
  const dispatch = useDispatch();
  const userSchema = useSelector(userSchemaSelector);
  const schemaLoading = useSelector(userSchemaLoadingSelector);
  const userConfig = useSelector(userConfigurationSelector);
  const configLoading = useSelector(userConfigurationLoadingSelector);
  const [localConfig, setLocalConfig] = useState(null);

  useEffect(() => {
    dispatch(getUserSchema());
    if (userId) {
      dispatch(getUserConfiguration({ userId }));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (userConfig) {
      setLocalConfig(userConfig);
    }
  }, [userConfig, userSchema]);

  const ToUpperCase = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  useImperativeHandle(ref, () => ({
    saveChanges: (newUserId) => {
      if (localConfig && userSchema) {
        if (newUserId) {
          const updatedConfig = {
            ...localConfig,
            ownerId: newUserId,
          };
          setLocalConfig(updatedConfig);
          dispatch(
            updateUserConfiguration({
              data: updatedConfig,
              schemaVersion: userSchema.version,
            })
          );
        } else {
          dispatch(
            updateUserConfiguration({
              data: localConfig,
              schemaVersion: userSchema.version,
            })
          );
        }
      }
    },
    discardChanges: () => {
      setLocalConfig(userConfig);
    },
  }));

  if (!localConfig || !userSchema) {
    return null;
  }

  const emailSettings = userSchema.jsonSchema.properties.EmailSettings;
  const emailConfig = localConfig.data.EmailSettings;

  const handleCheckboxChange = () => {
    const updatedConfig = {
      ...localConfig,
      data: {
        ...localConfig.data,
        EmailSettings: {
          ...localConfig.data.EmailSettings,
          Enabled: !localConfig.data.EmailSettings.Enabled,
        },
      },
    };
    setLocalConfig(updatedConfig);
  };

  const handleDropdownChange = (e) => {
    const updatedConfig = {
      ...localConfig,
      data: {
        ...localConfig.data,
        EmailSettings: {
          ...localConfig.data.EmailSettings,
          Frequency: e.target.value,
        },
      },
    };
    setLocalConfig(updatedConfig);
  };

  return (
    <Card className={`notifications-card ${editMode ? 'edit-mode' : ''}`}>
      <Card.Header className="notifications-card-header">
        Notifications
      </Card.Header>
      <Card.Body className="notifications-card-body">
        {(configLoading || schemaLoading) && <LoadingAnimation />}
        {!editMode ? (
          <DataPoint
            title="Receive Email Notifications"
            data={
              emailConfig?.Enabled
                ? ToUpperCase(emailConfig?.Frequency)
                : "None"
            }
          />
        ) : (
            <>
              <Checkbox
                label={emailSettings.properties.Enabled.description}
                name="receiveEmailNotification"
                checked={emailConfig?.Enabled}
                onChange={handleCheckboxChange}
              />
              <div className="dropdown-notification">
                <Dropdown
                  label={emailSettings.properties.Frequency.description}
                  name="selectEmailFrequency"
                  value={emailConfig.Frequency}
                  options={emailSettings.properties.Frequency.enum.map((value) => ({
                    value,
                    label: ToUpperCase(value),
                  }))}
                  onChange={handleDropdownChange}
                  disabled={!emailConfig?.Enabled}
                />
              </div>
            </>
        )}
      </Card.Body>
    </Card>
  );
});

NotificationsCard.displayName = "NotificationsCard";
export default NotificationsCard;
