import { put, takeEvery, call, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getWebhooks,
  getWebhooksSuccess,
  getWebhooksFailure,
  getWebhookDetails,
  getWebhookDetailsSuccess,
  getWebhookDetailsFailure,
  subscribeToWebhook,
  subscribeToWebhookSuccess,
  subscribeToWebhookFailure,
  updateWebhook,
  updateWebhookSuccess,
  updateWebhookFailure,
  deleteWebhookSubscription,
  deleteWebhookSubscriptionSuccess,
  deleteWebhookSubscriptionFailure,
  testWebhookSubscription,
  testWebhookSubscriptionSuccess,
  testWebhookSubscriptionFailure,
  getMessagesAuditInfo,
  getMessagesAuditInfoSuccess,
  getMessagesAuditInfoFailure,
  getWebhookAuditInfo,
  getWebhookAuditInfoSuccess,
  getWebhookAuditInfoFailure,
  searchWebhooks,
  searchWebhooksSuccess,
  searchWebhooksFailure,
} from "app/store/actions/notification"
import NotificationServices from 'app/services/notificationServices';

function* fetchWebhooks(action) {
  const { subscriberId } = action.payload;
  try {
    const data = yield call([NotificationServices, NotificationServices.getWebhooks], subscriberId);
    yield put(getWebhooksSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(getWebhooksFailure(error));
  }
}

function* fetchWebhookDetails(action) {
  const { webhookId } = action.payload;
  try {
    const data = yield call([NotificationServices, NotificationServices.getWebhookDetails], webhookId);
    yield put(getWebhookDetailsSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(getWebhookDetailsFailure(error));
  }
}

function* doSubscribeToWebhook(action) {
  const { data, cb } = action.payload;

  try {
    const resp = yield call([NotificationServices, NotificationServices.subscribeToWebhook], data);
    if (resp.id) {
      yield put(subscribeToWebhookSuccess());
      toast.success("Webhook Subscription Successful", {
        theme: 'colored',
      });
      if (cb) cb();
    } else {
      throw (resp);
    }
  } catch (error) {
    console.error('error', error);
    yield put(subscribeToWebhookFailure(error));
    toast.error("Webhook Subscription Failed", {
      theme: 'colored',
    });
  }
}

function* doUpdateWebhook(action) {
  const { data, showToast = false, cb } = action.payload;

  try {
    yield call([NotificationServices, NotificationServices.updateWebhook], data);
    yield put(updateWebhookSuccess(data));
    if (showToast) {
      toast.success("Webhook Updated Successfully", {
        theme: 'colored',
      });
    }
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(updateWebhookFailure(error));
    if (showToast) {
      toast.error("Webhook Update Failed", {
        theme: 'colored',
      });
    }
  }
}

function* doDeleteWebhookSubscription(action) {
  const { webhookId, cb } = action.payload;

  try {
    yield call([NotificationServices, NotificationServices.deleteWebhookSubscription], webhookId);
    yield put(deleteWebhookSubscriptionSuccess());
    toast.success("Webhook Successfully Deleted", {
      theme: 'colored',
    });
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(deleteWebhookSubscriptionFailure(error));
    toast.error("Webhook Deletion Failed", {
      theme: 'colored',
    });
  }
}

function* doTestWebhookSubscription(action) {
  const { callbackUrl, callbackMethod, headerInfo, cb } = action.payload;

  const headers = headerInfo.reduce((acc, header) => {
    if (header.key && header.value) {
      acc[header.key] = header.value;
    }
    return acc;
  }, {});

  try {
    const resp = yield call([NotificationServices, NotificationServices.testWebhookSubscription], callbackUrl, callbackMethod, headers);
    // only 200 status codes are considered successful
    if (resp.status !== 200) {
      throw new Error(`Server responded with status: ${resp.status}`);
    }
    const message = `Webhook Test Successful<br/>HTTP Status Code : ${resp.status}`;
    toast.success(<div dangerouslySetInnerHTML={{ __html: message }} />, {
      theme: 'colored',
    });
    yield put(testWebhookSubscriptionSuccess(resp));
    if (cb) cb(true);
  } catch (error) {
    console.error("Webhook Test Error : ", error);

    let errorMessage = 'Webhook test failed.';
    if (error.response) {
      errorMessage += ` Server responded with status: ${error.response.status}.`;
    } else if (error.request) {
      errorMessage += ' No response received.';
    } else {
      errorMessage += ` Error message: ${error.message}.`;
    }

    if (error.message.includes('Network Error') || error.message.includes('CORS')) {
      errorMessage += ' This may be due to a network or CORS configuration error.';
    }
    toast.error(errorMessage, {
      theme: 'colored',
    });
    yield put(testWebhookSubscriptionFailure(error));
    if (cb) cb(false);
  }
}

function* fetchMessagesAuditInfo(action) {
  const { auditUrl, cb } = action.payload;
  try {
    const resp = yield call([NotificationServices, NotificationServices.getMessagesAuditInfo], auditUrl);
    yield put(getMessagesAuditInfoSuccess());
    if(cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(getMessagesAuditInfoFailure(error));
    toast.error("Failed to get audit info", {
      theme: 'colored',
    })
  }
}

function* fetchWebhookAuditInfo(action) {
  const { auditUrl, cb } = action.payload;

  try {
    const resp = yield call([NotificationServices, NotificationServices.getWebhookAuditInfo], auditUrl);
    yield put(getWebhookAuditInfoSuccess());
    if(cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(getWebhookAuditInfoFailure(error));
    toast.error("Failed to get audit info", {
      theme: 'colored',
    })
  }
}

function* searchWebhooksHandler(action) {
  const { subscriberId } = action.payload;

  try {
    const data = yield call([NotificationServices, NotificationServices.searchWebhooks], subscriberId);
    yield put(searchWebhooksSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(searchWebhooksFailure(error));
  }
}

function* watchData() {
  yield takeEvery(getWebhooks.toString(), fetchWebhooks);
  yield takeEvery(getWebhookDetails.toString(), fetchWebhookDetails);
  yield takeEvery(subscribeToWebhook.toString(), doSubscribeToWebhook);
  yield takeEvery(updateWebhook.toString(), doUpdateWebhook);
  yield takeEvery(deleteWebhookSubscription.toString(), doDeleteWebhookSubscription);
  yield takeEvery(testWebhookSubscription.toString(), doTestWebhookSubscription);
  yield takeEvery(getMessagesAuditInfo.toString(), fetchMessagesAuditInfo);
  yield takeEvery(getWebhookAuditInfo.toString(), fetchWebhookAuditInfo);
  yield takeLatest(searchWebhooks.toString(), searchWebhooksHandler);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}