import { createSelector } from 'reselect';

const vendorSelector = state => state.vendor;

export const vendorsDataSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendors.data);
export const vendorsLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendors.loading);
export const vendorsErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendors.error);

export const vendorsBulkDataSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendorsBulk.data);
export const vendorsBulkLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendorsBulk.loading);
export const vendorsBulkErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendorsBulk.error);

export const vendorDetailsSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendor.data);
export const vendorDetailsLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendor.loading);
export const vendorDetailsErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendor.error);

export const facilityDetailsSelector = createSelector([vendorSelector], vendorStore => vendorStore.facility.data);
export const facilityDetailsLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.facility.loading);
export const facilityDetailsErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.facility.error);

export const vendorProductDataSelector = createSelector([vendorSelector], vendorStore => vendorStore.products.data);
export const vendorProductDataLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.products.loading);
export const vendorProductDataErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.products.error);

export const allVendorProductDataSelector = createSelector([vendorSelector], vendorStore => vendorStore.allProducts.data);
export const allVendorProductDataLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.allProducts.loading);
export const allVendorProductDataErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.allProducts.error);

export const addUpdateProductsLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.addUpdateProducts.loading);
export const addUpdateProductsErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.addUpdateProducts.error);

export const auditLogsSelector = createSelector([vendorSelector], vendorStore => vendorStore.auditLogs.data);
export const auditLogsLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.auditLogs.loading);
export const auditLogsErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.auditLogs.error);

export const vasSelector = createSelector([vendorSelector], vendorStore => vendorStore.vas.data);
export const vasLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.vas.loading);
export const vasErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.vas.error);

export const vendorSearchStringSelector = createSelector([vendorSelector], vendorStore => vendorStore.searchString);
export const vendorFacilityProductsSelector = createSelector([vendorSelector], vendorStore => vendorStore?.products?.data?.vendorFacilityProducts);

export const searchVendorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.searchVendors.data);
export const searchVendorsLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.searchVendors.loading);
export const searchVendorsErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.searchVendors.error);
export const searchVendorDataSelector = createSelector([searchVendorsSelector, vendorSearchStringSelector], (vendorData, searchString) => {
    return combineVendorData(vendorData, searchString);
});

export const bulkGetFacilitiesSelector = createSelector([vendorSelector], vendorStore => vendorStore.bulkFacilities.data);
export const bulkGetFacilitiesLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.bulkFacilities.loading);
export const bulkGetFacilitiesErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.bulkFacilities.error);

export const searchViewAsVendorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.searchViewAsVendors.data);
export const searchViewAsVendorsLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.searchViewAsVendors.loading);
export const searchViewAsVendorsErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.searchViewAsVendors.error);
export const searchViewAsVendorDataSelector = createSelector([searchViewAsVendorsSelector, vendorSearchStringSelector], (vendorData, searchString) => {
    return combineVendorData(vendorData, searchString);
});

const combineVendorData = (vendorData, searchString) => {
    // vendors and facilities are nested data. each vendor has a list of facilities. vendor ms search endpoint
    // returns such data format, and we have to flatten it to single item level, and perform a search on it.
    const vendorFacilities = vendorData?.vendors?.map(vendor => {
        const facilities = vendor.facilities.map(facility => {
            return {
                ...facility,
                vendorId: vendor.id,
                vendorName: vendor.name
            };
        });

        return facilities;
    })
        .flat()
        .filter(facility => facility.name?.toLowerCase().includes(searchString?.toLowerCase()));

    // now filter out vendors, because not all vendors match search criteria...
    const vendors = vendorData?.vendors?.filter(vendor => vendor.name.toLowerCase().includes(searchString.toLowerCase()));

    return vendors?.concat(vendorFacilities)?.sort((a, b) => a.name.localeCompare(b.name));
}