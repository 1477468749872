import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Modal, Button, LoadingAnimation } from 'app/components';
import { getVendorDetails } from 'app/store/actions/vendor';
import { vendorDetailsSelector, vendorDetailsLoadingSelector, vendorDetailsErrorsSelector } from 'app/store/selectors/vendor';
import { convertCountryCodeToName } from 'app/utils';
import './index.scss';

const FacilityInfoModal = props => {
  const { vendorId, showFacilityInfoModal } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vendorDetails = useSelector(vendorDetailsSelector);
  const vendorsLoading = useSelector(vendorDetailsLoadingSelector);
  const vendorErrors = useSelector(vendorDetailsErrorsSelector);

  useEffect(() => {
    dispatch(getVendorDetails({ vendorId }));
  }, [dispatch, vendorId]);

  return (
    <Modal
      className="facility-info-modal"
      title="Facility Information"
      hideButtons={true}
      onClose={() => showFacilityInfoModal(null)}
    >
      {vendorsLoading && (
        <div className="vendor-data-loading">
          <LoadingAnimation />
        </div>
      )}
      {!vendorsLoading && vendorDetails && (
        <>
          <div className="facility-info">
            <div className="details-row">
              <div className="details-label">Name:</div>
              <div className="details-data">{vendorDetails.name}</div>
            </div>
            <div className="details-row">
              <div className="details-label">Address:</div>
              <div className="details-data">{`${vendorDetails.address.line1}${vendorDetails.address.line2 ? ' ' + vendorDetails.address.line2 : ''} ${vendorDetails.address.city}, ${vendorDetails.address.state} ${convertCountryCodeToName(vendorDetails.address.countryCode)} ${vendorDetails.address.zip}`}</div>
            </div>
            <div className="details-row">
              <div className="details-label">Country:</div>
              <div className="details-data">{vendorDetails.address.countryCode}</div>
            </div>
            <div className="contact-header">Contact Information</div>
            <div className="details-row">
              <div className="details-label">Contact Name:</div>
              <div className="details-data">{vendorDetails.contacts[0]?.name || '-'}</div>
            </div>
            <div className="details-row">
              <div className="details-label">Role:</div>
              <div className="details-data">{vendorDetails.contacts[0]?.role || '-'}</div>
            </div>
            <div className="details-row">
              <div className="details-label">Phone:</div>
              <div className="details-data">{vendorDetails.contacts[0]?.phone || '-'}</div>
            </div>
            <div className="details-row">
              <div className="details-label">Email:</div>
              <div className="details-data">{vendorDetails.contacts[0]?.email || '-'}</div>
            </div>
          </div>
          <div className="action-buttons">
            <Button
              variant="secondary"
              size="small"
              label="Cancel"
              onClick={() => { showFacilityInfoModal(null) }}
            />
            {vendorDetails.url && (
              <Button
                size="small"
                variant="secondary"
                label="Go to Vendor Portal"
                onClick={() => window.open(vendorDetails.url, '_blank')}
              />
            )}
            <Button
              size="small"
              variant="primary"
              label="View Vendor"
              onClick={() => navigate("/admin/vendors/" + vendorId + "/details")}
            />
          </div>
        </>
      )}
      {vendorErrors && (
        <div className="vendor-info-error">
          Vendor Information failed to load.
        </div>
      )}
    </Modal>
  );
}

export default FacilityInfoModal;
