import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { queriesSelector, queriesLoadingSelector, queriesErrorsSelector, queryDataSelector } from 'app/store/selectors/insight';
import { Card, ButtonMenu, LoadingAnimation, MessageBar } from 'app/components';
import { getAllQueries, runQueryById } from 'app/store/actions/insight';
import { subscriberSelector } from 'app/store/selectors/user';
import { InfoCircle } from 'react-bootstrap-icons';
import OrdersByStatus from './OrdersByStatus';
import LateOrdersInDays from './LateOrdersInDays';
import LateOrdersByFacility from './LateOrdersByFacility';
import AverageFulfillmentTimeByVendor from './AverageFulfillmentTimeByVendor';
import TopProductsFulfilledByCategory from './TopProductsFulfilledByCategory';
import AverageFulfillmentTimeByFacility from './AverageFulfillmentTimeByFacility';
import TotalFulfilledShipmentCountByFacility from './TotalFulfilledShipmentCountByFacility';
import EmptyChart from './EmptyChart';
import './index.scss';

const componentMapping = {
  'orders-count-by-status': [OrdersByStatus],
  'late-order-shipments': [LateOrdersInDays, LateOrdersByFacility],
  'avg-fulfillment-time-by-vendor': [AverageFulfillmentTimeByVendor],
  'top-5-product-category-fulfilled': [TopProductsFulfilledByCategory],
  'avg-fulfillment-time-by-vendor-facility': [AverageFulfillmentTimeByFacility],
  'shipments-count-by-status-and-vendor-facility': [TotalFulfilledShipmentCountByFacility],
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const allQueries = useSelector(queriesSelector);
  const allQueriesLoading = useSelector(queriesLoadingSelector);
  const allQueriesErrors = useSelector(queriesErrorsSelector);
  const queryData = useSelector(queryDataSelector);
  const subscriber = useSelector(subscriberSelector);
  const [filteredQueries, setFilteredQueries] = useState([]);

  const queries = [
    { queryId: 'orders-count-by-status', runAs: 'admin' }, // 9 - half done, Ahad doing rework (combined with 11)
    { queryId: 'late-order-shipments', runAs: 'admin' }, // 10 and 18 - done
    // { queryId: 'shipments-count-by-status', runAs: 'admin' }, // 11 - half done, Ahad doing rework (combined with 9)
    { queryId: 'shipments-count-by-status-and-vendor-facility', runAs: 'admin' }, // 12 - done
    // { queryId: 'top-5-sku-fulfilled', runAs: 'admin' }, // 13 - not needed
    { queryId: 'top-5-product-category-fulfilled', runAs: 'admin' }, // 14 - done
    // { queryId: 'top-5-us-state-by-orders-count', runAs: 'admin' }, // 15 - not needed
    // { queryId: 'top-5-vendor-sku-fulfilled', runAs: 'admin' }, // 16 - not needed
    { queryId: 'avg-fulfillment-time-by-vendor', runAs: 'admin' }, // 17 - done
    // { queryId: 'late-order-shipments-count-by-vendor-facility', runAs: 'admin' }, // 18 - done (uses same query as 10)
    { queryId: 'avg-fulfillment-time-by-vendor-facility', runAs: 'admin' }, // 19 - done
  ];

  const dateRange = parseInt(searchParams.get('dateRange') || '30');

  useEffect(() => {
    dispatch(getAllQueries());
  }, []);

  useEffect(() => {
    if (allQueries && allQueries.length > 0) {
      runQueries();
    }
  }, [allQueries, dateRange]);

  const runQueries = () => {
    let queriesToRun = queries
      .filter(query => {
        const apiQuery = allQueries.find(apiItem => apiItem.id === query.queryId);
        return apiQuery && subscriber && apiQuery.allowForUserTypes.includes(subscriber.entity);
      })
      .map(query => ({ ...query, runAs: subscriber.entity }));

    setFilteredQueries(queriesToRun);

    queriesToRun.forEach(query => {
      const id = `${query.queryId}-${dateRange}`;
      if (!queryData[id] || !queryData[id].refreshedAt || (new Date() - new Date(queryData[id].refreshedAt)) > 300000) { // 5 minutes
        dispatch(runQueryById({ query, dateRange }));
      }
    });
  }

  const updateDateRange = (val) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('dateRange', val.value);
    setSearchParams(updatedSearchParams.toString());
  }

  return (
    <div className="dashboard-view">
      {allQueriesLoading && <LoadingAnimation />}
      <Card className="dashboard-view-header">
        <div className="title-and-date">
          Dashboard
          <ButtonMenu
            label={`Last ${dateRange} Days`}
            variant="secondary"
            size="small"
            options={[
              { value: '7', label: '7 Days' },
              { value: '30', label: '30 Days' },
              { value: '90', label: '90 Days' },
            ].map(val => ({
              value: val.value,
              label: val.label,
              onClick: () => updateDateRange(val)
            }))}
            width={140}
          />
        </div>
        {!allQueriesErrors && (
          <div className="last-updated">
            {queryData['orders-count-by-status' + '-' + dateRange]?.refreshedAt ? (
              <>
                <InfoCircle /> {`Last Updated: ${queryData['orders-count-by-status' + '-' + dateRange].refreshedAt}`}
              </>
            ) : (
              <>
                &nbsp;
              </>
            )}
          </div>
        )}
      </Card>
      {!allQueriesErrors ? (
        <>
          <OrdersByStatus queryData={queryData['orders-count-by-status' + '-' + dateRange]} /> {/* 9 & 11 - half done */}
          <div className="charts-container">
            {filteredQueries.filter(query => query.queryId !== 'orders-count-by-status').map(query => {
              const ComponentArray = componentMapping[query.queryId];
              const id = `${query.queryId}-${dateRange}`;
              return ComponentArray ? ComponentArray.map((Component, idx) => <Component key={`${query.queryId}-${idx}`} queryData={queryData[id]} />) : null;
            })}
          </div>
        </>
      ) : (
        <>
          <MessageBar color="yellow">
            An error occurred while loading data
          </MessageBar>
          <div className="charts-container">
            <EmptyChart title="Orders By Status" />
            <EmptyChart title="Late Orders in Days" />
            <EmptyChart title="Average Fulfillment Time by Vendor" />
            <EmptyChart title="Total Fulfilled Shipment Count by Facility" />
          </div>
        </>
      )}
    </div>
  )
}

export default Dashboard;
