import React from 'react';
import { ClientManager } from 'app/components'

const AuthView = ({ merchantId }) => {
  return (
    <div className="merchant-auth-view">
      <ClientManager entityType="merchant" entityId={merchantId}></ClientManager>
    </div>
  )
}

export default AuthView;