import {
  createClient,
  createClientSuccess,
  createClientFailure,
  getClient,
  getClientSuccess,
  getClientFailure,
  generateSecret,
  generateSecretSuccess,
  generateSecretFailure,
  deleteClient,
  deleteClientSuccess,
  deleteClientFailure,
  toggleGenerateSecret,
  toggleDeleteClient,
  cancel,
  reset,
} from "app/store/actions/client";
import { handleActions } from 'redux-actions';

const initialState = {
    confirmGenerateSecret: false,
    confirmDeleteClient: false,
    loading: false,
    data: null,
    error: null,
}

const clientReducer = handleActions(
  {
    [getClient]: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),

    [getClientSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),

    [getClientFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: null,
      error: payload,
    }),

    [createClient]: (state) => ({
      ...state,
      loading: true,
      data: null,
    }),

    [createClientSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),

    [createClientFailure]: (state) => ({
      ...state,
      loading: false,
      data: null,
    }),

    [toggleGenerateSecret]: (state) => ({
      ...state,
      confirmGenerateSecret: true,
    }),

    [generateSecret]: (state) => ({
      ...state,
      loading: true,
      confirmGenerateSecret: false,
    }),

    [generateSecretSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),

    [generateSecretFailure]: (state) => ({
      ...state,
      loading: false,
    }),

    [toggleDeleteClient]: (state) => ({
      ...state,
      confirmDeleteClient: true,
    }),

    [deleteClient]: (state) => ({
      ...state,
      loading: true,
      confirmDeleteClient: false,
    }),

    [deleteClientSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),

    [deleteClientFailure]: (state) => ({
      ...state,
      loading: false,
    }),

    [cancel]: (state) => ({
      ...state,
      confirmGenerateSecret: false,
      confirmDeleteClient: false,
    }),

    [reset]: () => ({
      ...initialState
    })
  },
  initialState,
)

export default clientReducer;
