import { combineReducers } from 'redux';
import userReducer from 'app/store/reducers/user';
import orderReducer from 'app/store/reducers/order';
import catalogReducer from 'app/store/reducers/catalog';
import configReducer from 'app/store/reducers/config';
import vendorReducer from 'app/store/reducers/vendor';
import merchantReducer from 'app/store/reducers/merchant';
import shipmentReducer from 'app/store/reducers/shipment';
import routingReducer from 'app/store/reducers/routing';
import transformationReducer from 'app/store/reducers/transformation';
import historyReducer from 'app/store/reducers/history';
import notificationReducer from 'app/store/reducers/notification';
import integrationReducer from 'app/store/reducers/integration';
import insightReducer from 'app/store/reducers/insight';
import inventoryReducer from "./inventory";
import noteReducer from 'app/store/reducers/note';
import clientReducer from 'app/store/reducers/client';
import { RESET_ALL } from 'app/store/actions/user';

const appReducer = combineReducers({
  user: userReducer,
  order: orderReducer,
  catalog: catalogReducer,
  config: configReducer,
  vendor: vendorReducer,
  merchant: merchantReducer,
  shipment: shipmentReducer,
  routing: routingReducer,
  transformation: transformationReducer,
  history: historyReducer,
  notification: notificationReducer,
  integration: integrationReducer,
  insight: insightReducer,
  inventory: inventoryReducer,
  note: noteReducer,
  client: clientReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_ALL) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
