import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, SearchBar, Table } from 'app/components';
import { XCircleFill, ChevronDown } from 'react-bootstrap-icons';
import './index.scss';

const MultiSelectSearch = ({
  items,
  selected,
  onChange,
  label,
  errorMessage = '',
  onSearchStringChange,
  onClearSelection,
  onItemHover,
  showHoverTooltip = false,
  hoverTooltip = '',
  placeholder = 'Select Items',
}) => {
  const [showContent, setShowContent] = useState(false);
  const dropdownRef = useRef(null);

  const showError = errorMessage && errorMessage.trim().length > 0;

  // Close dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowContent(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMouseEnter = (item) => {
    if (onItemHover) {
      onItemHover(item);
    }
  };

  const handleMouseLeave = () => {
    if (onItemHover) {
      onItemHover(null);
    }
  };

  return (
    <div className="multi-select-search" ref={dropdownRef}>
      {label && <label>{label}</label>}
      <div
        className='content-holder'
        onClick={() => setShowContent((prev) => !prev)}
      >
        <div className='selected-holder'>
          <span className={selected.length === 0 ? 'selected-placeholder' : ''}>
            {selected.length > 0
              ? (selected.length < 4
                ? selected.map(item => item.label).join(', ')
                : `You have selected ${selected.length} items`)
              : placeholder
            }
          </span>
          <div className='search-toolbar'>
            {selected.length > 0 && <XCircleFill className="clear-icon" onClick={onClearSelection} />}
            <ChevronDown />
          </div>
        </div>
      </div>
      {showContent && (
        <div className='search-content'>
          <SearchBar
            searchPlaceholderText='Search'
            onSearchStringUpdated={onSearchStringChange}
          />
          <Table className='search-table'>
            <div className='table-body'>
              {items.map((item, pos) => (
                <tr
                  className="table-item"
                  key={`${item.value}${pos}`}
                  onMouseEnter={() => handleMouseEnter(item)}
                  onMouseLeave={handleMouseLeave}
                >
                  <td className={`table-item-td ${selected.map(v => v.value).includes(item.value) ? 'selected' : ''}`}>
                    <div
                      className="line-item"
                      onClick={() =>
                        onChange(selected.includes(item)
                          ? selected.filter(v => v.value !== item.value)
                          : [...selected, item])
                      }
                    >
                      <Checkbox
                        size="small"
                        checked={selected.map(v => v.value).includes(item.value)}
                      />
                      <div>{item.label}</div>
                    </div>
                  </td>
                </tr>
              ))}
            </div>
          </Table>
          {showHoverTooltip && <div className="hover-tooltip">
            {hoverTooltip}
          </div>}
          {showError && <div className="error">{errorMessage}</div>}
        </div>
      )}
    </div>
  );
};

MultiSelectSearch.propTypes = {
  items: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onSearchStringChange: PropTypes.func.isRequired,
  onClearSelection: PropTypes.func.isRequired,
  onItemHover: PropTypes.func,
  showHoverTooltip: PropTypes.bool,
  hoverTooltip: PropTypes.string,
  placeholder: PropTypes.string,
};

export default MultiSelectSearch;
