import { all } from 'redux-saga/effects';
import UserSaga from 'app/store/sagas/user';
import OrderSaga from 'app/store/sagas/order';
import MerchantSaga from 'app/store/sagas/merchant';
import CatalogSaga from 'app/store/sagas/catalog';
import VendorSaga from 'app/store/sagas/vendor';
import ShipmentSaga from 'app/store/sagas/shipment';
import RoutingSaga from 'app/store/sagas/routing';
import TransformationSaga from 'app/store/sagas/transformation';
import HistorySaga from 'app/store/sagas/history';
import NotificationSaga from 'app/store/sagas/notification';
import IntegrationSaga from 'app/store/sagas/integration';
import InsightServices from 'app/store/sagas/insight';
import InventorySaga from 'app/store/sagas/inventory';
import NoteSaga from 'app/store/sagas/note';
import ClientSaga from 'app/store/sagas/client';
import FilesSaga from 'app/store/sagas/files';

function* rootSaga() {
  yield all([
    UserSaga(),
    OrderSaga(),
    MerchantSaga(),
    CatalogSaga(),
    VendorSaga(),
    ShipmentSaga(),
    RoutingSaga(),
    TransformationSaga(),
    HistorySaga(),
    NotificationSaga(),
    IntegrationSaga(),
    InsightServices(),
    InventorySaga(),
    NoteSaga(),
    ClientSaga(),
    FilesSaga(),
  ]);
}

export default rootSaga;