import React, { useMemo } from 'react';
import { sidebarSelector } from 'app/store/selectors/config';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getOrders } from 'app/store/actions/order';
import { currentUserSelector } from 'app/store/selectors/user';
import ViewAsMenu from './ViewAsMenu';
import {
  House,
  HouseFill,
  Handbag,
  HandbagFill,
  People,
  PeopleFill,
  Shop,
  Truck,
  SignTurnSlightRight,
  SignTurnSlightRightFill,
  Bell,
  BellFill,
  Plug,
  PlugFill,
} from 'react-bootstrap-icons';
import { ReactComponent as ShopFilledIcon } from 'app/assets/images/icons/shop-filled.svg';
import { ReactComponent as TruckFilledIcon } from 'app/assets/images/icons/truck-filled.svg';
import { ReactComponent as Inventory} from 'app/assets/images/icons/inventory.svg';
import { ReactComponent as InventoryFilled} from 'app/assets/images/icons/inventory-filled.svg'
import { usePermission, isSuperAdmin } from 'app/permissions';
import './index.scss';

const Sidebar = ({userData}) => {
  const { t } = useTranslation();
  const sidebar = useSelector(sidebarSelector);
  const location = useLocation();
  const dispatch = useDispatch();

  const canGetOrders = usePermission('order', 'search');
  const canReadInsights = usePermission('insight', 'get');
  const canGetIntegrations = usePermission('integration', 'plugins_get');
  const canGetStrategies = usePermission('routing', 'strategies_get');
  const canGetNotifications = usePermission('notification', 'get');
  const canSearchUsers = usePermission('users', 'search');
  const canCreateUsers = usePermission('users', 'users_create');
  const canSearchVendors = usePermission('vendor', 'search');
  const canSearchMerchants = usePermission('merchant', 'get');
  const canSearchInventory = usePermission('vendor', 'search') || usePermission('vendor', 'create');

  const currentUser = useSelector(currentUserSelector);
  const superAdmin = isSuperAdmin();

  const { associatedIds, merchantIds, vendorIds } = currentUser.attributes || {};

  const shouldShowViewAsMenu = useMemo(() => {
    return associatedIds?.length > 0 || superAdmin || merchantIds?.length > 1 || vendorIds?.length > 1;
  }, [associatedIds]);

  const routes = useMemo(() => [
    { path: '/', name: t('general.home'), icon: House, iconSelected: HouseFill, permission: canReadInsights },
    { path: '/orders', name: t('general.orders'), icon: Handbag, iconSelected: HandbagFill, permission: canGetOrders },
  ], [t, canReadInsights, canGetOrders]);

  const adminRoutes = useMemo(() => [
    { path: '/admin/users', name: 'Users & Roles', icon: People, iconSelected: PeopleFill, permission: canSearchUsers || canCreateUsers },
    { path: '/admin/merchants', name: 'Merchants', icon: Shop, iconSelected: ShopFilledIcon, permission: canSearchMerchants },
    { path: '/admin/vendors', name: 'Vendors', icon: Truck, iconSelected: TruckFilledIcon, permission: canSearchVendors },
    { path: '/admin/routing', name: 'Routing', icon: SignTurnSlightRight, iconSelected: SignTurnSlightRightFill, permission: canGetStrategies },
    { path: '/admin/webhooks', name: 'Webhooks', icon: Bell, iconSelected: BellFill, permission: canGetNotifications },
    { path: '/admin/integrations', name: 'Integrations', icon: Plug, iconSelected: PlugFill, permission: canGetIntegrations },
    { path: '/admin/inventory', name: 'Inventory', icon: Inventory, iconSelected: InventoryFilled, permission: canSearchInventory },
  ], [canSearchUsers, canSearchMerchants, canSearchVendors, canGetStrategies, canGetNotifications, canGetIntegrations]);

  const handleOrdersClick = () => {
    const fullPath = window.location.pathname + window.location.search;
    
    if (fullPath === '/orders') {
      dispatch(getOrders({}));
    }
  };

  const renderedRoutes = useMemo(() => routes.map((route, index) => {
    if (route.permission === false) {
      return null;
    }

    const basePath = location.pathname.split('/')[1];
    const isActive = `/${basePath}` === route.path;

    return (
      <React.Fragment key={route.path}>
        <Link
          key={index}
          to={route.path}
          onClick={route.path === '/orders' ? handleOrdersClick : null}
          className={`sidebar-link ${isActive ? 'active' : ''}`}
        >
          {isActive ? <route.iconSelected /> : <route.icon />}
          {route.name}
        </Link>
      </React.Fragment>
    );
  }), [routes, location.pathname]);

  const renderedAdminRoutes = useMemo(() => adminRoutes.map((route, index) => {
    if (route.permission === false) {
      return null;
    }

    const basePath = location.pathname.split('/')[2];
    const isActive = `/admin/${basePath}` === route.path;

    return (
      <Link
        key={index}
        to={route.path}
        className={`sidebar-link ${isActive ? 'active' : ''}`}
      >
        {isActive ? <route.iconSelected /> : <route.icon />}
        {route.name}
      </Link>
    );
  }), [adminRoutes, location.pathname]);

  return (
    <div className={`sidebar ${sidebar.open ? 'show' : ''}`}>
      {shouldShowViewAsMenu && <ViewAsMenu userData={userData} />}
      <div className={`sidebar-container  ${shouldShowViewAsMenu ? 'view-as-shown' : ''}`}>
        {renderedRoutes}
        {renderedAdminRoutes}
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
