import {
  getMerchants,
  getMerchantsSuccess,
  getMerchantsFailure,
  getMerchantDetails,
  getMerchantDetailsSuccess,
  getMerchantDetailsFailure,
  createMerchant,
  createMerchantSuccess,
  createMerchantFailure,
  updateMerchant,
  updateMerchantSuccess,
  updateMerchantFailure,
  updateMerchantsParent,
  updateMerchantsParentSuccess,
  updateMerchantsParentFailure,
  updateMerchantsChildren,
  updateMerchantsChildrenSuccess,
  updateMerchantsChildrenFailure,
  removeAllAssociations,
  removeAllAssociationsSuccess,
  removeAllAssociationsFailure,
  getTemplates,
  getTemplatesFailure,
  getTemplatesSuccess,
  clearErrors,
  reset,
  deleteTemplate,
  deleteTemplateFailure,
  deleteTemplateSuccess,
  updateTemplate,
  updateTemplateFailure,
  updateTemplateSuccess,
  getTemplate,
  getTemplateSuccess,
  getTemplateFailure,
  resetTemplate,
  createTemplate,
  createTemplateSuccess,
  createTemplateFailure,
  renderTemplate,
  renderTemplateSuccess,
  renderTemplateFailure,
  getConfiguration,
  getConfigurationFailure,
  getConfigurationSuccess,
  getMerchantSchema,
  getMerchantSchemaSuccess,
  getMerchantSchemaFailure,
  updateMerchantConfiguration,
  createMerchantConfiguration,
  updateMerchantConfigurationSuccess,
  updateMerchantConfigurationFailure,
  createMerchantConfigurationSuccess,
  createMerchantConfigurationFailure,
  searchMerchants,
  searchMerchantsSuccess,
  searchMerchantsFailure,
  bulkGetMerchants,
  bulkGetMerchantsSuccess,
  bulkGetMerchantsFailure,
  searchViewAsMerchants,
  searchViewAsMerchantsSuccess,
  searchViewAsMerchantsFailure
} from "app/store/actions/merchant"
import { handleActions } from 'redux-actions';

const initialState = {
  merchants: {
    loading: false,
    data: null,
    error: null,
  },
  searchMerchants: {
    loading: false,
    data: null,
    error: null,
  },
  searchViewAsMerchants: {
    loading: false,
    data: null,
    error: null
  },
  merchant: {
    loading: false,
    data: null,
    error: null,
  },
  merchantProducts: {
    loading: false,
    data: null,
    error: null,
  },
  merchantTemplates: {
    loading: false,
    data: null,
    error: null
  },
  merchantTemplate: {
    loading: false,
    data: null,
    error: null
  },
  merchantConfiguration: {
    loading: false,
    data: null,
    error: null
  },
  merchantSchema: {
    loading: false,
    data: null,
    error: null
  },
  bulkMerchants: {
    loading: false,
    data: null,
    error: null
  }
}

const merchantReducer = handleActions(
  {
    [getMerchants]: (state) => ({
      ...state,
      merchants: {
        ...state.merchants,
        loading: true,
        error: null,
      }
    }),

    [getMerchantsSuccess]: (state, data) => {
      // remove any merchants that do not have a name
      const merchants = data.payload.merchants.filter(merchant => merchant.name);

      return ({
        ...state,
        merchants: {
          ...state.merchants,
          loading: false,
          data: { merchants, total: data.payload.total },
        }
      })
    },

    [getMerchantsFailure]: (state, data) => ({
      ...state,
      merchants: {
        ...state.merchants,
        loading: false,
        error: data.payload,
      }
    }),

    [getMerchantDetails]: (state) => ({
      ...state,
      merchant: {
        ...state.merchant,
        loading: true,
        error: null,
      },
    }),

    [getMerchantDetailsSuccess]: (state, data) => ({
      ...state,
      merchant: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getMerchantDetailsFailure]: (state, data) => ({
      ...state,
      merchant: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [createMerchant]: (state) => ({
      ...state,
      merchant: {
        ...state.merchant,
        loading: true,
        error: null,
      },
    }),

    [createMerchantSuccess]: (state) => ({
      ...state,
      merchant: {
        ...state.merchant,
        loading: false,
      },
    }),

    [createMerchantFailure]: (state, data) => ({
      ...state,
      merchant: {
        ...state.merchant,
        loading: false,
        error: data.payload,
      },
    }),

    [updateMerchant]: (state) => ({
      ...state,
      merchant: {
        ...state.merchant,
        loading: true,
        error: null,
      },
    }),

    [updateMerchantSuccess]: (state, data) => ({
      ...state,
      merchant: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [updateMerchantFailure]: (state, data) => ({
      ...state,
      merchant: {
        ...state.merchant,
        loading: false,
        error: data.payload,
      },
    }),

    [updateMerchantsParent]: (state) => ({
      ...state,
      merchant: {
        ...state.merchant,
        loading: true,
        error: null,
      },
    }),

    [updateMerchantsParentSuccess]: (state, data) => ({
      ...state,
      merchant: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [updateMerchantsParentFailure]: (state, data) => ({
      ...state,
      merchant: {
        ...state.merchant,
        loading: false,
        error: data.payload,
      },
    }),

    [updateMerchantsChildren]: (state) => ({
      ...state,
      merchant: {
        ...state.merchant,
        loading: true,
        error: null,
      },
    }),

    [updateMerchantsChildrenSuccess]: (state, data) => ({
      ...state,
      merchant: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [updateMerchantsChildrenFailure]: (state, data) => ({
      ...state,
      merchant: {
        ...state.merchant,
        loading: false,
        error: data.payload,
      },
    }),

    [removeAllAssociations]: (state) => ({
      ...state,
      merchant: {
        ...state.merchant,
        loading: true,
        error: null,
      },
    }),

    [removeAllAssociationsSuccess]: (state, data) => ({
      ...state,
      merchant: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [removeAllAssociationsFailure]: (state, data) => ({
      ...state,
      merchant: {
        ...state.merchant,
        loading: false,
        error: data.payload,
      },
    }),

    [getTemplates]: (state) => ({
      ...state,
      merchantTemplates: {
        ...state.merchantTemplates,
        loading: true,
        error: null,
      }
    }),

    [getTemplatesSuccess]: (state, data) => ({
      ...state,
      merchantTemplates: {
        loading: false,
        data: Object.groupBy(data?.payload?.templates, ({ category }) => category),
        error: null,
      }
    }),

    [getTemplatesFailure]: (state, data) => ({
      ...state,
      merchantTemplates: {
        ...state.merchantTemplates,
        loading: false,
        error: data.payload,
      }
    }),

    [deleteTemplate]: (state) => ({
      ...state,
      merchantTemplates: {
        ...state.merchantTemplates,
        loading: true,
        error: null,
      }
    }),

    [deleteTemplateSuccess]: (state) => ({
      ...state,
      merchantTemplates: {
        ...state.merchantTemplates,
        loading: false,
        error: null
      }
    }),

    [deleteTemplateFailure]: (state, data) => ({
      ...state,
      merchantTemplates: {
        ...state.merchantTemplates,
        loading: false,
        error: data.payload,
      }
    }),

    [updateTemplate]: (state) => ({
      ...state,
      merchantTemplates: {
        ...state.merchantTemplates,
        loading: true,
        error: null,
      }
    }),

    [updateTemplateSuccess]: (state) => ({
      ...state,
      merchantTemplates: {
        ...state.merchantTemplates,
        loading: false,
        error: null
      }
    }),

    [updateTemplateFailure]: (state, data) => ({
      ...state,
      merchantTemplates: {
        ...state.merchantTemplates,
        loading: false,
        error: data.payload,
      }
    }),

    [getTemplate]: (state) => ({
      ...state,
      merchantTemplate: {
        ...state.merchantTemplate,
        loading: true,
        error: null,
      }
    }),

    [getTemplateSuccess]: (state, data) => ({
      ...state,
      merchantTemplate: {
        loading: false,
        data: data.payload,
        error: null,
      }
    }),

    [getTemplateFailure]: (state, data) => ({
      ...state,
      merchantTemplate: {
        ...state.merchantTemplate,
        loading: false,
        error: data.payload,
      }
    }),

    [renderTemplate]: (state) => ({
      ...state,
      merchantTemplate: {
        ...state.merchantTemplate,
        loading: true,
        error: null,
      }
    }),

    [renderTemplateSuccess]: (state, data) => ({
      ...state,
      merchantTemplate: {
        ...state.merchantTemplate,
        renderData: data.payload,
        loading: false,
        error: null,
      }
    }),

    [renderTemplateFailure]: (state) => ({
      ...state,
      merchantTemplate: {
        ...state.merchantTemplate,
        loading: false,
        error: null
      }
    }),

    [createTemplate]: (state) => ({
      ...state,
      merchantTemplate: {
        ...state.merchantTemplate,
        loading: true,
        error: null,
      }
    }),

    [createTemplateSuccess]: (state) => ({
      ...state,
      merchantTemplate: {
        loading: false,
        data: null,
        error: null,
      }
    }),

    [createTemplateFailure]: (state, data) => ({
      ...state,
      merchantTemplate: {
        loading: false,
        data: null,
        error: data.payload,
      }
    }),

    [resetTemplate]: (state) => ({
      ...state,
      merchantTemplate: {
        loading: false,
        data: null,
        error: null,
      }
    }),

    [getConfiguration]: (state) => ({
      ...state,
      merchantConfiguration: {
        ...state.merchantConfiguration,
        loading: true,
        error: null,
      }
    }),

    [getConfigurationSuccess]: (state, data) => ({
      ...state,
      merchantConfiguration: {
        loading: false,
        data: data.payload,
        error: null,
      }
    }),

    [getConfigurationFailure]: (state, data) => ({
      ...state,
      merchantConfiguration: {
        ...state.merchantConfiguration,
        loading: false,
        error: data.payload,
      }
    }),

    [getMerchantSchema]: (state) => ({
      ...state,
      merchantSchema: {
        ...state.merchantSchema,
        loading: true,
        error: null,
      }
    }),

    [getMerchantSchemaSuccess]: (state, data) => ({
      ...state,
      merchantSchema: {
        loading: false,
        data: data.payload,
        error: null,
      }
    }),

    [getMerchantSchemaFailure]: (state, data) => ({
      ...state,
      merchantSchema: {
        ...state.merchantSchema,
        loading: false,
        error: data.payload,
      }
    }),

    [updateMerchantConfiguration]: (state) => ({
      ...state,
      merchantConfiguration: {
        ...state.merchantConfiguration,
        loading: true,
        error: null,
      }
    }),

    [updateMerchantConfigurationSuccess]: (state) => ({
      ...state,
      merchantConfiguration: {
        ...state.merchantConfiguration,
        loading: false,
        error: null,
      }
    }),

    [updateMerchantConfigurationFailure]: (state, data) => ({
      ...state,
      merchantConfiguration: {
        ...state.merchantConfiguration,
        loading: false,
        error: data.payload,
      }
    }),

    [createMerchantConfiguration]: (state) => ({
      ...state,
      merchantConfiguration: {
        ...state.merchantConfiguration,
        loading: true,
        error: null,
      }
    }),

    [createMerchantConfigurationSuccess]: (state) => ({
      ...state,
      merchantConfiguration: {
        ...state.merchantConfiguration,
        loading: false,
        error: null,
      }
    }),

    [createMerchantConfigurationFailure]: (state, data) => ({
      ...state,
      merchantConfiguration: {
        ...state.merchantConfiguration,
        loading: false,
        error: data.payload,
      }
    }),

    [searchMerchants]: (state) => ({
      ...state,
      searchMerchants: {
        ...state.searchMerchants,
        loading: true,
        error: null,
      }
    }),

    [searchMerchantsSuccess]: (state, data) => ({
      ...state,
      searchMerchants: {
        loading: false,
        data: data.payload,
        error: null,
      }
    }),

    [searchMerchantsFailure]: (state, data) => ({
      ...state,
      searchMerchants: {
        ...state.searchMerchants,
        loading: false,
        error: data.payload,
      }
    }),

    [bulkGetMerchants]: (state) => ({
      ...state,
      bulkMerchants: {
        ...state.bulkMerchants,
        loading: true,
        error: null,
      }
    }),

    [bulkGetMerchantsSuccess]: (state, data) => ({
      ...state,
      bulkMerchants: {
        loading: false,
        data: data.payload,
        error: null,
      }
    }),

    [bulkGetMerchantsFailure]: (state, data) => ({
      ...state,
      bulkMerchants: {
        ...state.bulkMerchants,
        loading: false,
        error: data.payload,
      }
    }),

    [searchViewAsMerchants]: (state) => ({
      ...state,
      searchViewAsMerchants: {
        ...state.searchViewAsMerchants,
        loading: true,
        error: null,
      }
    }),

    [searchViewAsMerchantsSuccess]: (state, data) => ({
      ...state,
      searchViewAsMerchants: {
        loading: false,
        data: data.payload,
        error: null,
      }
    }),

    [searchViewAsMerchantsFailure]: (state, data) => ({
      ...state,
      searchViewAsMerchants: {
        ...state.searchViewAsMerchants,
        loading: false,
        error: data.payload,
      }
    }),

    [clearErrors]: (state) => ({
      ...state,
      merchants: {
        ...state.merchants,
        error: null,
      },
      merchant: {
        ...state.merchant,
        error: null,
      },
      merchantProducts: {
        ...state.merchantProducts,
        error: null,
      },
      merchantTemplates: {
        ...state.merchantTemplates,
        error: null,
      },
      merchantTemplate: {
        ...state.merchantTemplate,
        error: null,
      },
      merchantConfiguration: {
        ...state.merchantConfiguration,
        error: null,
      },
      merchantSchema: {
        ...state.merchantSchema,
        error: null,
      },
      bulkMerchants: {
        ...state.bulkMerchants,
        error: null
      },
      searchViewAsMerchants: {
        ...state.searchViewAsMerchants,
        error: null
      }
    }),

    [reset]: () => ({
      ...initialState
    })
  },
  initialState,
)

export default merchantReducer;