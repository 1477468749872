import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  reset,
  deleteRole,
  searchRoles,
} from "app/store/actions/user";
import {
  searchRolesDataSelector,
  searchRolesLoadingSelector,
  searchRolesErrorsSelector,
} from "app/store/selectors/user";
import {
  Pagination,
  LoadingAnimation,
  SearchBar,
  ButtonIcon,
  Table,
  TableHeader,
  Button,
  Modal,
  Card,
  Link,
} from "app/components";
import { ExclamationCircle, Trash3, ArrowLeft } from "react-bootstrap-icons";
import { formatUsersRolesName } from "../utils";
import { usePermission } from 'app/permissions';
import "./index.scss";

const RolesView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [searchString, setSearchString] = useState("");
  const [selectedSortBy, setSelectedSortBy] = useState("");
  const [selectedSortDir, setSelectedSortDir] = useState("");
  const [deleteRoleModal, showDeleteRoleModal] = useState(false);

  const rolesData = useSelector(searchRolesDataSelector);
  const loading = useSelector(searchRolesLoadingSelector);
  const errorsFound = useSelector(searchRolesErrorsSelector);

  const currentPage = parseInt(searchParams.get("page") || "1", 10);
  const pageSize = parseInt(searchParams.get("pageSize") || "50", 10);
  const sortBy = searchParams.get("sortBy") || "id";
  const sortDir = searchParams.get("sortDir") || "desc";

  // get user permissions
  const canManageRoles = usePermission('users', 'roles_create');

  useEffect(() => {
    dispatch(searchRoles({ searchString, currentPage, pageSize }));
    }, [searchString, currentPage, pageSize, dispatch]);

  useEffect(() => {
    handleLocationSearch();
  }, [location.search]);

  useEffect(() => {
    handleSearchChange();
  }, [selectedSortBy, selectedSortDir]);

  const handleLocationSearch = () => {
    const searchParams = new URLSearchParams(location.search);

    // set sort by if it has been changed...
    const sortBy = searchParams.get("sortBy");
    if (sortBy && sortBy !== selectedSortBy) {
      setSelectedSortBy(sortBy);
    }

    // set sort direction if it has been changed...
    const sortDir = searchParams.get("sortDir");
    if (sortDir && sortDir !== selectedSortDir) {
      setSelectedSortDir(sortDir);
    }
  };

  const handleSearchChange = () => {
    dispatch(
      searchRoles({
        searchString,
        selectedSortBy,
        selectedSortDir,
        currentPage,
        pageSize,
      })
    );
  };

  return (
    <div className="role-view">
      {loading && <LoadingAnimation />}
      {rolesData && (
        <Card>
          <div className="roles-view-header">
            <div className="role-name">
              <ButtonIcon
                icon={<ArrowLeft />}
                onClick={() => navigate("/admin/users/")}
              />
              Role Management
            </div>
            {canManageRoles && (
              <Button
                variant="primary"
                size="medium"
                label={"Create Role"}
                onClick={() => {
                  dispatch(reset());
                  navigate(`/admin/users/roles/create-role`);
                }}
              />
            )}
          </div>
          <SearchBar
            searchPlaceholderText="Search for a Role"
            onSearchStringUpdated={setSearchString}
            debounceDelay={500}
          />
          <Table size="medium">
            <TableHeader
              sortBy={sortBy}
              sortDir={sortDir}
              options={[
                { id: "name", label: "Role", width: "240px", orderable: true },
                { id: "resources", label: "Permissions" },
                { id: "userCount", label: "User Count", orderable: true},
                ...(canManageRoles ? [{ id: "actions", label: "Actions", width: "50px" }] : []),
              ]}
            />
            <tbody className="table-body">
              {rolesData?.roles.map((role) => (
                <tr className="role-row" key={role.id}>
                  <td>
                    <Link
                      label={formatUsersRolesName(role.name)}
                      url={`/admin/users/roles/${role.id}`}
                      onClick={() => {
                        dispatch(reset());
                        navigate(`/admin/users/roles/${role.id}`);
                      }}
                    />{" "}
                  </td>
                  <td>
                    {role.permissionSet
                      ?.filter(
                        (permission) =>
                          permission.associatedPermissions.length > 0
                      )
                      ?.map((r) => (
                        <div key={r?.resourceId}>
                          {formatUsersRolesName(r?.resourceDisplayName)}
                        </div>
                      ))}
                  </td>
                  <td>{role.usersCount}</td>
                  {canManageRoles && (
                    <td className="role-actions-menu">
                      <ButtonIcon
                        disabled={role.name.toLowerCase() == "admin"}
                        icon={<Trash3 />}
                        destructive
                        onClick={() => showDeleteRoleModal(role.id)}
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          {errorsFound && !loading && (
            <div className="role-load-failed"><ExclamationCircle />Role Data failed to load. Refresh the page to try again.</div>
          )}
          {rolesData?.roles.length === 0 ? (
            <div className="no-matching-results">No Results Found</div>
          ) : (
            <Pagination totalItems={rolesData?.total || 0} hideBelow={3} />
          )}
          {deleteRoleModal && (
            <Modal
              secondaryButtonLabel="No, Keep It"
              secondaryButtonOnClick={() => {
                showDeleteRoleModal(null);
              }}
              primaryButtonLabel="Yes, Delete It"
              primaryButtonVariant="primary"
              primaryButtonDestructive
              primaryButtonOnClick={() => {
                dispatch(
                  deleteRole({
                    id: deleteRoleModal,
                    cb: () => handleSearchChange(),
                  })
                );
                showDeleteRoleModal(null);
              }}
              onClose={() => showDeleteRoleModal(null)}
            >
              <div>Are you sure you want to delete this role?</div>
              <div>This action is not reversible.</div>
            </Modal>
          )}
        </Card>
      )}
    </div>
  );
};
export default RolesView;
