import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { Form, InputGroup } from 'react-bootstrap';
import { Search, Filter, XCircleFill } from 'react-bootstrap-icons';
import { Button } from 'app/components';
import './index.scss';

const SearchBar = ({
  searchPlaceholderText = '',  // the placeholder text that is displayed within the search field (optional)
  onSearchStringUpdated,       // the function to be called whenever the search field input has changed
  addFilterButton = false,     // do you want to show a filter button next to the search field? (defaults to false)
  isFilterMenuOpen = false,    // a boolean value indicating if the filter menu is open or not
  onFilterButtonPressed = null,// the function to be called whenever the filter button is pressed
  addCtaButton = false,        // a boolean value indicating if you want to show a call-to-action button (defaults to false)
  ctaButtonText = '',          // the text that appears on the cta button
  onCtaButtonPressed = null,   // the function to be called whenever the cta button is pressed
  ctaButtonIcon = null,        // the icon to be displayed on the cta button (optional)
  className = '',              // custom css styling if needed (optional)
  debounceDelay = 0,           // the delay in milliseconds to wait before calling the onSearchStringUpdated function (optional)
  disabled = false,            // a boolean value indicating if the search field should be disabled (optional)
  allowClear = true,           // a boolean value indicating if the clear button should be displayed (optional)
  allowSearchParams = true       // a boolean value indicating if the search params should be used (optional)
}) => {
  // state to track the current input and debounced input values
  const [searchParams] = useSearchParams();
  const [inputValue, setInputValue] = useState((allowSearchParams && searchParams.get('searchString')) || '');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [isUserInput, setIsUserInput] = useState(false);

  // effect for debouncing
  useEffect(() => {
    // set up a timer to update the debounced value after the specified delay
    const timer = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, debounceDelay || 0);

    // clear the timer if the component is unmounted or the value changes
    return () => clearTimeout(timer);
  }, [inputValue]); 

  // effect to call the onSearchStringUpdated when the debounced value changes
  useEffect(() => {
    // only call onSearchStringUpdated if the user has interacted
    if (isUserInput) {
      onSearchStringUpdated(debouncedValue);
    }
  }, [debouncedValue]);

  // function to clear the search field
  const clearSearch = () => {
    setInputValue('');
    setIsUserInput(true);
    onSearchStringUpdated('');
  };

  return (
    <div className={`search-bar ${className}`}>
      <InputGroup>
        <InputGroup.Text id="basic-addon1">
          <Search />
        </InputGroup.Text>
        <Form.Control
          placeholder={searchPlaceholderText}
          aria-label={searchPlaceholderText}
          value={inputValue}
          disabled={disabled}
          onChange={(e) => {
            setIsUserInput(true);
            setInputValue(e.target.value);
          }}
        />
        {allowClear && inputValue && !disabled && (
          <InputGroup.Text id="clear-search" onClick={clearSearch}>
            <XCircleFill />
          </InputGroup.Text>
        )}
      </InputGroup>
      {addFilterButton && (
        <Button
          variant="secondary"
          size="small"
          label={isFilterMenuOpen ? 'Clear Filters' : 'Filters'}
          onClick={() => onFilterButtonPressed()}
          imageLeft={<Filter />}
          imageLeftOffset={-5}
        />
      )}
      {addCtaButton && (
        <Button
          variant="primary"
          size="small"
          label={ctaButtonText}
          onClick={() => onCtaButtonPressed()}
          imageLeft={ctaButtonIcon}
          imageLeftOffset={-5}
        />
      )}
    </div>
  );
};

SearchBar.propTypes = {
  searchPlaceholderText: PropTypes.string,
  onSearchStringUpdated: PropTypes.func.isRequired,
  addFilterButton: PropTypes.bool,
  isFilterMenuOpen: PropTypes.bool,
  onFilterButtonPressed: PropTypes.func,
  addCtaButton: PropTypes.bool,
  ctaButtonText: PropTypes.string,
  onCtaButtonPressed: PropTypes.func,
  ctaButtonIcon: PropTypes.object,
  className: PropTypes.string,
  debounceDelay: PropTypes.number,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  allowSearchParams: PropTypes.bool
};

export default SearchBar;
