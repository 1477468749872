import React from 'react';
import { Card } from 'app/components';
import { convertCountryCodeToName } from 'app/utils';
import './index.scss';

const PaymentInformation = (props) => {
  const { paymentMethods } = props;

  // Render nothing if there are no payment methods
  if (!paymentMethods || paymentMethods.length === 0) {
    return null;
  }

  return (
    <Card className="payment-information">
      <Card.Header>
        Payment Information
      </Card.Header>
      <Card.Body>
        {paymentMethods.map((paymentMethod, index) => {
          const billingInfo = paymentMethod.billingInfo;

          const address = billingInfo?.address ? 
            `${billingInfo.address.line1}${billingInfo.address.line2 ? ' ' + billingInfo.address.line2 : ''} ${billingInfo.address.city}, ${billingInfo.address.state} ${convertCountryCodeToName(billingInfo.address.countryCode)} ${billingInfo.address.zip}` 
            : null;

          return (
            <div key={index} className="payment-method">
              {billingInfo.fullName && <div className="data-value">{billingInfo.fullName}</div>}
              {address && <div className="data-value">{address}</div>}
              {billingInfo.address.phone && <div className="data-value">{billingInfo.address.phone}</div>}
              {paymentMethod.cardLast4 && <div className="data-value">{`********${paymentMethod.cardLast4}`}</div>}
            </div>
          );
        })}
      </Card.Body>
    </Card>
  );
};

export default PaymentInformation;
