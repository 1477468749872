import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { userAuthSelector, currentUserSelector } from 'app/store/selectors/user';
import { isMerchant, isSuperAdmin, isVendor, usePermission } from 'app/permissions';
import Home from 'app/pages/Home';
import Orders from 'app/pages/Orders';
// import PlaceAnOrder from 'app/pages/PlaceAnOrder';
import Users from 'app/pages/Users';
import Merchants from 'app/pages/Merchants';
import Vendors from 'app/pages/Vendors';
import Routing from 'app/pages/Routing';
import Webhooks from 'app/pages/Webhooks';
import Integrations from 'app/pages/Integrations';
import Inventory from './pages/Inventory';
import SignIn from 'app/pages/Signin';
import ForgotPassword from './pages/ForgotPassword';
import { Sidebar, Topbar, MainContent } from 'app/components';
import ReactGA from 'react-ga4';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'typeface-source-sans-pro';
import './lang/i18n';
import './App.scss';

// initialize Google Analytics globally when the app loads (only for staging andd prod)
const gaTrackingId = window.location.hostname.includes('portal.stg.ordermesh.io') ? process.env.REACT_APP_GA_TRACKING_ID_STG : window.location.hostname.includes('portal.ordermesh.io') ? process.env.REACT_APP_GA_TRACKING_ID_PROD : null;
if (gaTrackingId) {
  ReactGA.initialize(gaTrackingId);
}

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

function AppContent() {
  const location = useLocation();
  const userAuth = useSelector(userAuthSelector);
  const currentUser = useSelector(currentUserSelector);
  
  // get user permissions
  const canGetOrders = usePermission('order', 'search');
  const canReadInsights = usePermission('insight', 'get');
  const canGetIntegrations = usePermission('integration', 'plugins_get');
  const canGetStrategies = usePermission('routing', 'strategies_get');
  const canGetNotifications = usePermission('notification', 'get');
  const canSearchUsers = usePermission('users', 'search');
  const canCreateUsers = usePermission('users', 'users_create');
  const canSearchVendors = usePermission('vendor', 'search');
  const canSearchMerchants = usePermission('merchant', 'get');

  const superAdmin = isSuperAdmin();
  const vendor = isVendor();
  const merchant = isMerchant();

  // set user-specific GA data when the user logs in
  useEffect(() => {
    if (gaTrackingId && currentUser?.id) {
      ReactGA.set({
        user_id: currentUser.id,
        user_type: superAdmin ? 'super-admin' : vendor ? 'vendor' : merchant ? 'merchant' : '',
        user_name: currentUser.firstName + ' ' + currentUser.lastName,
      });
    }
  }, [currentUser]);

  // track page views on route change 
  useEffect(() => {
    if (gaTrackingId) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }
  }, [location]);

  const findNextAvailableRoute = () => {
    const routes = [
      { permission: canReadInsights, route: '/' },
      { permission: canGetOrders, route: '/orders' },
      { permission: canSearchUsers || canCreateUsers, route: '/admin/users' },
      { permission: canSearchVendors, route: '/admin/vendors' },
      { permission: canSearchMerchants, route: '/admin/merchants' },
      { permission: canGetStrategies, route: '/admin/routing' },
      { permission: canGetNotifications, route: '/admin/webhooks' },
      { permission: canGetIntegrations, route: '/admin/integrations' },
    ];

    const nextRoute = routes.find((route) => route.permission);
    return nextRoute ? nextRoute.route : '/';
  };

  return (
    <div className="app">
      <ToastContainer pauseOnFocusLoss={false} hideProgressBar={true} draggable={false} />
      {userAuth.bearerToken ? (
        <>
          <ScrollToTop />
          <Topbar showOrderSearchBar={canGetOrders} />
          <div className="main-container">
            <Sidebar userData={{ superAdmin, vendor, merchant }} />
            <MainContent>
              <Routes>
                <Route path="*" element={canReadInsights ? <Home /> : <Navigate to={findNextAvailableRoute()} replace />} />
                {canGetOrders && <Route path="/orders/*" element={<Orders />} />}
                {/* <Route path="/place-an-order/*" element={<PlaceAnOrder />} /> */}
                {(canSearchUsers || canCreateUsers) && <Route path="/admin/users/*" element={<Users />} />}
                {canSearchVendors && <Route path="/admin/vendors/*" element={<Vendors />} />}
                {canSearchMerchants && <Route path="/admin/merchants/*" element={<Merchants />} />}
                {canGetStrategies && <Route path="/admin/routing/*" element={<Routing />} />}
                {canGetNotifications && <Route path="/admin/webhooks/*" element={<Webhooks />} />}
                {canGetIntegrations && <Route path="/admin/integrations/*" element={<Integrations />} />}
                <Route path="/admin/inventory/*" element={<Inventory />} />
                <Route path="*" element={<Navigate to={findNextAvailableRoute()} replace />} />
              </Routes>
            </MainContent>
          </div>
        </>
      ) : (
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/forgot-password/:token" element={<ForgotPassword />} />
          <Route path="*" element={<Navigate to="/signin" replace />} />
        </Routes>
      )}
    </div>
  );
}

export default App;

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [pathname]);
  return null;
}