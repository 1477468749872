import React, { useState } from "react";
import { Button, Input, LoadingAnimation } from "app/components";
import { Formik } from "formik";
import { object, ref, string } from "yup";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import { requestForgotPassword, updateForgotPassword } from "app/store/actions/user";
import "./index.scss";

const ForgotPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showLoading, setshowLoading] = useState(false);

  const forgotPassword = () => {
    return (
      <div className="forgot-password">
        {showLoading && <LoadingAnimation />}
        <div className="title-text">Password Reset</div>
        {token}
        <Formik
          enableReinitialize
          initialValues={{
            email: "",
          }}
          validationSchema={() =>
            object().shape({
              email: string()
                .required("Email is required")
                .email("Invalid email address"),
            })
          }
          onSubmit={async (values) => {
            setshowLoading(true);
            dispatch(
              requestForgotPassword({
                email: values.email,
                cb: () => {
                  setshowLoading(false);
                  navigate("/signin");
                },
                failCB: () => {
                  setshowLoading(false);
                },
              })
            );
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            submitCount,
          }) => (
            <form
              onSubmit={handleSubmit}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSubmit();
                  event.preventDefault();
                }
              }}
            >
              <div className="input-container">
                <Input
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  errorMessage={submitCount > 0 && errors.email}
                />
              </div>
              <div className="submit-button-container">
                <Button
                  className="mx-2"
                  variant="secondary"
                  size="small"
                  label="Go Back"
                  disabled={isSubmitting}
                  onClick={() => navigate("/signin")}
                />
                <Button
                  variant="primary"
                  size="small"
                  label="Reset Password"
                  disabled={isSubmitting}
                  onClick={() => handleSubmit()}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  };

  const resetPassword = () => {
    return (
      <div className="forgot-password">
         {showLoading && <LoadingAnimation />}
        <div className="title-text">Reset password</div>
        <div className="mb-3">Please enter your new password</div>
        <Formik
          enableReinitialize
          initialValues={{
            password: "",
            passwordConfirmation: "",
          }}
          validationSchema={() =>
            object().shape({
              password: string().required("Password is required"),
              passwordConfirmation: string()
                .required("Please confirm your password")
                .oneOf([ref("password"), null], "Passwords must match"),
            })
          }
          onSubmit={async (values) => {
            setshowLoading(true);
            dispatch(
              updateForgotPassword({
                token: token,
                password: values.password,
                cb: () => {
                  setshowLoading(false);
                  navigate("/signin");
                },
                failCB: () => {
                  setshowLoading(false);
                },
              })
            );
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            submitCount,
          }) => (
            <form
              onSubmit={handleSubmit}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSubmit();
                  event.preventDefault();
                }
              }}
            >
              <div className="input-container">
                <Input
                  label="New password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  icon={showPassword ? <EyeSlashFill /> : <EyeFill />}
                  onIconClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  errorMessage={submitCount > 0 && errors.password}
                  type={showPassword ? "text" : "password"}
                />
                <Input
                  label="Confirm password"
                  name="passwordConfirmation"
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  icon={showPassword ? <EyeSlashFill /> : <EyeFill />}
                  onIconClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  errorMessage={submitCount > 0 && errors.passwordConfirmation}
                  type={showPassword ? "text" : "password"}
                />
              </div>
              <div className="submit-button-container">
                <Button
                  variant="primary"
                  size="small"
                  label="Update password"
                  disabled={isSubmitting}
                  onClick={() => handleSubmit()}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  };

  return token ? resetPassword() : forgotPassword();
};

export default ForgotPassword;
