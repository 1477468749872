import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThreeDotsVertical, ExclamationTriangleFill } from 'react-bootstrap-icons';
import { Image, Table, TableHeader, ButtonIcon, Link, MessageBar, Button, LoadingAnimation } from 'app/components';
import ReplaceImageModal from '../../../Modals/ReplaceImageModal';
import { getImageInfo } from 'app/store/actions/files';
import './index.scss';

const AdditionalItemDetails = (props) => {
  const { orderId, itemInfo } = props;
  const dispatch = useDispatch();

  const [imageDetails, setImageDetails] = useState(null);
  const [imageLoadError, setImageLoadError] = useState(false);
  const [showReplaceImageModal, setShowReplaceImageModal] = useState(false);

  useEffect(() => {
    dispatch(getImageInfo({ images: itemInfo.images, cb: setImageDetails, cbError: () => setImageLoadError(true) }));
  }, [itemInfo.images, dispatch]);

  const imageOptions = () => {
    return [
      {
        value: 'Replace Image',
        label: 'Replace Image',
        onClick: () => setShowReplaceImageModal(true),
      },
    ];
  };

  if (!imageDetails && !imageLoadError) {
    return (
      <div className="additional-item-details image-info-loading">
        <LoadingAnimation fullscreen={false} />
      </div>
    ); 
  }

  if (imageLoadError) {
    return (
      <div className="additional-item-details image-load-error">
        <div className="image-load-error-message"><ExclamationTriangleFill /> Error loading image details</div>
      </div>
    );
  }

  const getImageFileExtension = (image) => {
    const url = image.url || image.ourUrl || image.thumbnailUrl;
    return url.split('.').pop();
  };

  return (
    <div className="additional-item-details">
      <div className="details-container">
        <div className="item-image">
          <Image src={imageDetails[0].ourThumbnailUrl || imageDetails[0].thumbnailUrl || imageDetails[0].url || imageDetails[0].ourUrl} alt={itemInfo.name} />
        </div>
        {itemInfo.options && (
          <div className={`item-details ${itemInfo.options.length > 4 ? 'many' : ''}`}>
            {itemInfo.options.map(option => (
              <div className="item-property" key={option.name}>
                <div className="property-name">{option.name}</div>
                <div className="property-value">{option.value}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="image-details">
        <Table size="small" className="image-details-table">
          <TableHeader
            options={[
              { id: 'Print Ready Image', label: 'Print Ready Image' },
              { id: 'Image Size', label: 'Image Size' },
              { id: 'Image Manip', label: 'Image Manip' },
              { id: 'Print Method', label: 'Print Method' },
              { id: 'Actions', label: 'Actions', align: 'center' },
            ]}
          />
          <tbody className="table-body">
            {imageDetails.map((image) => (
              <tr className="image-row" key={image.area}>
                <td className="our-url">
                  {image.ourUrl || image.url || image.thumbnailUrl ? (
                    <Link
                      label={`${image.area}.${image.imageInfo?.format?.toLowerCase() || getImageFileExtension(image)}` || 'View Image'}
                      isRoute={false}
                      url={image.url || image.ourUrl || image.thumbnailUrl}
                    />
                  ) : (
                    <div>-</div>
                  )}
                </td>
                <td>{image.imageInfo ? `${image.imageInfo.width} x ${image.imageInfo.height} px ` : '-'}</td>
                <td>{image.manipCommand ? 'True' : 'False'}</td>
                <td>
                  {image.printMethod ? image.printMethod : '-'}
                </td>
                <td className="action-icon">
                  <ButtonIcon
                    icon={<ThreeDotsVertical />}
                    options={imageOptions(image)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {itemInfo.status === 'imageerror' && (
          <MessageBar color="red" className="image-error-message-bar">
            <div>
              {`We encountered an item with an image issue. For more details `}
              <Link
                label="View History Logs"
                url="history"
              />.
            </div>
            <Button
              label="Replace Image"
              destructive
              size="small"
              onClick={() => setShowReplaceImageModal(true)}
            />
          </MessageBar>
        )}
      </div>
      {showReplaceImageModal && (
        <ReplaceImageModal
          orderId={orderId}
          itemId={itemInfo.id}
          setShowReplaceImageModal={setShowReplaceImageModal}
        />
      )}
    </div>
  );
}

export default AdditionalItemDetails;
