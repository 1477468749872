import { createAction } from 'redux-actions';

const prefix = "MERCHANT";

export const getMerchants = createAction(`${prefix}/GET_MERCHANTS`);
export const getMerchantsSuccess = createAction(`${prefix}/GET_MERCHANTS_SUCCESS`);
export const getMerchantsFailure = createAction(`${prefix}/GET_MERCHANTS_FAILURE`);

export const getMerchantDetails = createAction(`${prefix}/GET_MERCHANT_DETAILS`);
export const getMerchantDetailsSuccess = createAction(`${prefix}/GET_MERCHANT_DETAILS_SUCCESS`);
export const getMerchantDetailsFailure = createAction(`${prefix}/GET_MERCHANT_DETAILS_FAILURE`);

export const createMerchant = createAction(`${prefix}/CREATE_MERCHANT`);
export const createMerchantSuccess = createAction(`${prefix}/CREATE_MERCHANT_SUCCESS`);
export const createMerchantFailure = createAction(`${prefix}/CREATE_MERCHANT_FAILURE`);

export const updateMerchant = createAction(`${prefix}/UPDATE_MERCHANT`);
export const updateMerchantSuccess = createAction(`${prefix}/UPDATE_MERCHANT_SUCCESS`);
export const updateMerchantFailure = createAction(`${prefix}/UPDATE_MERCHANT_FAILURE`);

export const updateMerchantsParent = createAction(`${prefix}/UPDATE_MERCHANTS_PARENT`);
export const updateMerchantsParentSuccess = createAction(`${prefix}/UPDATE_MERCHANTS_PARENT_SUCCESS`);
export const updateMerchantsParentFailure = createAction(`${prefix}/UPDATE_MERCHANTS_PARENT_FAILURE`);

export const updateMerchantsChildren = createAction(`${prefix}/UPDATE_MERCHANTS_CHILDREN`);
export const updateMerchantsChildrenSuccess = createAction(`${prefix}/UPDATE_MERCHANTS_CHILDREN_SUCCESS`);
export const updateMerchantsChildrenFailure = createAction(`${prefix}/UPDATE_MERCHANTS_CHILDREN_FAILURE`);

export const removeAllAssociations = createAction(`${prefix}/REMOVE_ALL_ASSOCIATIONS`);
export const removeAllAssociationsSuccess = createAction(`${prefix}/REMOVE_ALL_ASSOCIATIONS_SUCCESS`);
export const removeAllAssociationsFailure = createAction(`${prefix}/REMOVE_ALL_ASSOCIATIONS_FAILURE`);

export const getTemplates = createAction(`${prefix}/GET_TEMPLATES`);
export const getTemplatesSuccess = createAction(`${prefix}/GET_TEMPLATES_SUCCESS`);
export const getTemplatesFailure = createAction(`${prefix}/GET_TEMPLATES_FAILURE`);

export const getTemplate = createAction(`${prefix}/GET_TEMPLATE`);
export const getTemplateSuccess = createAction(`${prefix}/GET_TEMPLATE_SUCCESS`);
export const getTemplateFailure = createAction(`${prefix}/GET_TEMPLATE_FAILURE`);

export const deleteTemplate = createAction(`${prefix}/DELETE_TEMPLATE`);
export const deleteTemplateSuccess = createAction(`${prefix}/DELETE_TEMPLATE_SUCCESS`);
export const deleteTemplateFailure = createAction(`${prefix}/DELETE_TEMPLATE_FAILURE`);

export const updateTemplate = createAction(`${prefix}/UPDATE_TEMPLATE`);
export const updateTemplateSuccess = createAction(`${prefix}/UPDATE_TEMPLATE_SUCCESS`);
export const updateTemplateFailure = createAction(`${prefix}/UPDATE_TEMPLATE_FAILURE`);

export const createTemplate = createAction(`${prefix}/CREATE_TEMPLATE`);
export const createTemplateSuccess = createAction(`${prefix}/CREATE_TEMPLATE_SUCCESS`);
export const createTemplateFailure = createAction(`${prefix}/CREATE_TEMPLATE_FAILURE`);

export const resetTemplate = createAction(`${prefix}/RESET_TEMPLATE`);

export const renderTemplate = createAction(`${prefix}/RENDER_TEMPLATE`);
export const renderTemplateSuccess = createAction(`${prefix}/RENDER_TEMPLATE_SUCCESS`);
export const renderTemplateFailure = createAction(`${prefix}/RENDER_TEMPLATE_FAILURE`);

export const getConfiguration = createAction(`${prefix}/GET_CONFIGURATION`);
export const getConfigurationSuccess = createAction(`${prefix}/GET_CONFIGURATION_SUCCESS`);
export const getConfigurationFailure = createAction(`${prefix}/GET_CONFIGURATION_FAILURE`);

export const updateMerchantConfiguration = createAction(`${prefix}/UPDATE_MERCHANT_CONFIGURATION`);
export const updateMerchantConfigurationSuccess = createAction(`${prefix}/UPDATE_MERCHANT_CONFIGURATION_SUCCESS`);
export const updateMerchantConfigurationFailure = createAction(`${prefix}/UPDATE_MERCHANT_CONFIGURATION_FAILURE`);

export const getMerchantSchema = createAction(`${prefix}/GET_MERCHANT_SCHEMA`);
export const getMerchantSchemaSuccess = createAction(`${prefix}/GET_MERCHANT_SCHEMA_SUCCESS`);
export const getMerchantSchemaFailure = createAction(`${prefix}/GET_MERCHANT_SCHEMA_FAILURE`);

export const createMerchantConfiguration = createAction(`${prefix}/CREATE_MERCHANT_CONFIGURATION`);
export const createMerchantConfigurationSuccess = createAction(`${prefix}/CREATE_MERCHANT_CONFIGURATION_SUCCESS`);
export const createMerchantConfigurationFailure = createAction(`${prefix}/CREATE_MERCHANT_CONFIGURATION_FAILURE`);

export const searchMerchants = createAction(`${prefix}/SEARCH_MERCHANTS`);
export const searchMerchantsSuccess = createAction(`${prefix}/SEARCH_MERCHANTS_SUCCESS`);
export const searchMerchantsFailure = createAction(`${prefix}/SEARCH_MERCHANTS_FAILURE`);

export const searchViewAsMerchants = createAction(`${prefix}/SEARCH_VIEW_AS_MERCHANTS`);
export const searchViewAsMerchantsSuccess = createAction(`${prefix}/SEARCH_VIEW_AS_MERCHANTS_SUCCESS`);
export const searchViewAsMerchantsFailure = createAction(`${prefix}/SEARCH_VIEW_AS_MERCHANTS_FAILURE`);

export const bulkGetMerchants = createAction(`${prefix}/BULK_GET_MERCHANTS`);
export const bulkGetMerchantsSuccess = createAction(`${prefix}/BULK_GET_MERCHANTS_SUCCESS`);
export const bulkGetMerchantsFailure = createAction(`${prefix}/BULK_GET_MERCHANTS_FAILURE`);

export const clearErrors = createAction(`${prefix}/CLEAR_ERRORS`);
export const reset = createAction(`${prefix}/RESET`);