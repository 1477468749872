import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class NotificationServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.NotificationMS.Url
    });

    SetupInterceptors(this.api);
  }

  getWebhooks = async (subscriberId) => {
    try {
      const response = await this.api.get(`/v1?subscriberId=${subscriberId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  getWebhookDetails = async (webhookId) => {
    try {
      const response = await this.api.get(`/v1/${webhookId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  subscribeToWebhook = async (data) => {
    try {
      const response = await this.api.post('/v1', data);
      return response.data;
    } catch (error) {
      throw new Error('Error subscribing to webhook');
    }
  };

  updateWebhook = async (data) => {
    try {
      const response = await this.api.put('/v1', data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating webhook details');
    }
  };

  deleteWebhookSubscription = async (webhookId) => {
    try {
      const response = await this.api.delete(`/v1/${webhookId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error deleting webhook subscription');
    }
  };

  testWebhookSubscription = async (callbackUrl, callbackMethod, headers) => {
    try {
      const response = await axios({
        method: callbackMethod.toLowerCase(),
        url: callbackUrl,
        headers: headers,
        data: { test: "data" }
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

  getMessagesAuditInfo = async (auditUrl) => {
    try {
      const response = await this.api.get(auditUrl);
      return response.data;
    }
    catch (error) {
      throw new Error('Error fetching audit info');
    }
  }

  getWebhookAuditInfo = async (auditUrl) => {
    try {
      auditUrl = auditUrl.replace(/.*notification/, '');
      auditUrl = auditUrl.replace('DIFF', 'FULL');

      const response = await this.api.get(auditUrl);
      return response.data;
    }
    catch (error) {
      throw new Error('Error fetching audit info');
    }
  }

  searchWebhooks = async (searchParams) => {
    let queryString = "";

    const addParam = (key, value) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // check if searchParams is array of strings
    if (Array.isArray(searchParams)) {
      searchParams.forEach((param) => {
        addParam("subscriberIds", param);
      });
    } else {
      addParam('subscriberIds', searchParams);
    }

    try {
      const response = await this.api.get(`/v1/search?${queryString}`);
      return response.data;
    } catch (error) {
      throw new Error('Error searching webhooks');
    }
  };
}

export default new NotificationServices();
