import React from "react";
import {
  DataPoint,
  Dropdown,
  Input,
  MultiSelect,
  ButtonIcon,
} from "app/components";
import { PlusLg, Trash3 } from "react-bootstrap-icons";
import { CountryList } from "app/constants";
import "./index.scss";
import { Operators, Conditions } from "../../Utils";

const Filter = (props) => {
  const {
    blockIndex,
    filterIndex,
    values,
    data,
    categories,
    editMode,
    addFilter,
    removeFilter,
    errors,
    handleChange,
    setFieldValue,
    validateForm,
    submitCount,
  } = props;

  const availableConditions = [
    { value: "-", label: "-" },
    { value: Conditions.ShipToCountry.Name, label: "Ships To Country" },
    { value: Conditions.Quantity.Name, label: "Quantity" },
    { value: Conditions.ProductCategory.Name, label: "Product Category" },
    { value: Conditions.OrderMeshSKU.Name, label: "OrderMesh SKU" },
    { value: Conditions.CustomerShippingMethod.Name, label: "Customer Shipping Method" },
    { value: Conditions.CustomerOrderTotal.Name, label: "Customer Order Total" },
    { value: Conditions.CustomField.Name, label: "Custom Field" },
  ];

  const getOperators = (condition) => {
    switch (condition) {
      case Conditions.ShipToCountry.Name:
        return [{ value: Conditions.ShipToCountry.Operators.IsEqualTo, label: "is equal to" }];
      case Conditions.Quantity.Name:
        return [
          { value: Conditions.Quantity.Operators.IsLessThan, label: "is less than" },
          { value: Conditions.Quantity.Operators.IsGreaterThan, label: "is greater than" },
          { value: Conditions.Quantity.Operators.IsBetween, label: "is between" }
        ];
      case Conditions.ProductCategory.Name:
        return [
          { value: Conditions.ProductCategory.Operators.IsEqualTo, label: "is equal to" },
          { value: Conditions.ProductCategory.Operators.Contains, label: "contains" }
        ];
      case Conditions.OrderMeshSKU.Name:
        return [
          { value: Conditions.OrderMeshSKU.Operators.IsEqualTo, label: "is equal to" },
          { value: Conditions.OrderMeshSKU.Operators.Contains, label: "contains" }
        ];
      case Conditions.CustomerShippingMethod.Name:
        return [
          { value: Conditions.CustomerShippingMethod.Operators.IsEqualTo, label: "is equal to" }
        ];
      case Conditions.CustomerOrderTotal.Name:
        return [
          { value: Conditions.CustomerOrderTotal.Operators.IsEqualTo, label: "is equal to" },
          { value: Conditions.CustomerOrderTotal.Operators.IsNotEqualTo, label: "is not equal to" },
          { value: Conditions.CustomerOrderTotal.Operators.IsLessThan, label: "is less than" },
          { value: Conditions.CustomerOrderTotal.Operators.IsLessThanOrEqual, label: "is less than or equal" },
          { value: Conditions.CustomerOrderTotal.Operators.IsGreaterThan, label: "is greater than" },
          { value: Conditions.CustomerOrderTotal.Operators.IsGreaterThanOrEqual, label: "is greater than or equal" },
          { value: Conditions.CustomerOrderTotal.Operators.IsBetween, label: "is between" }
        ];
      default:
        return [
          { value: Operators.IsEqualTo, label: "is equal to" },
          { value: Operators.Contains, label: "contains" },
        ];
    }
  };

  const getUniqueConditions = () => {
    // get a list of all the conditions used by other filters in this block (excluding the current filter)
    // but allow the '-' option to be used multiple times
    const conditions = values.blocks[blockIndex].filterInfo.filters
      .filter((_, index) => index !== filterIndex)
      .map((filter) => filter.condition)
      .filter((condition) => condition !== "-");

    // now only return conditionOptions that are not in the conditions array
    return availableConditions.filter(
      (option) => !conditions.includes(option.value)
    );
  };

  const getValueInput = (data) => {
    if (data.condition === Conditions.ShipToCountry.Name) {
      if (editMode) {
        return (
          <MultiSelect
            name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
            label="Select Countries"
            options={CountryList.map((country) => ({
              value: country.CountryCode,
              label: `${country.CountryCode} - ${country.Name}`,
            }))}
            value={data.value}
            readonly={!editMode}
            onChange={(e) => {
              const val = {
                target: {
                  name: `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`,
                  value: e,
                },
              };
              handleChange(val);
            }}
            errorMessage={
              submitCount > 0 &&
              errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]
                ?.value &&
              "Select 1 or more Countries"
            }
          />
        );
      } else {
        return (
          <DataPoint
            title="Countries"
            maxWidth="350px"
            data={data.value.map((country) => country.label).join(", ")}
          />
        );
      }
    }

    if (data.condition === Conditions.ProductCategory.Name) {
      if (editMode) {
        if (data.logic === Conditions.ProductCategory.Operators.Contains) {
          return (
            <Input
              label={conditionSelected ? "Contains" : ""}
              name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
              value={data.value}
              readonly={!conditionSelected || !editMode}
              onChange={(e) => setFieldValue(e.target.name, e.target.value)}
              placeholder={conditionSelected ? "Contains" : ""}
              errorMessage={
                submitCount > 0 &&
                errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]
                  ?.value
              }
            />
          );
        }
        if (data.logic === Conditions.ProductCategory.Operators.IsEqualTo) {
          return (
            <MultiSelect
              name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
              options={categories
                .filter((category) => category.id !== "all")
                .map((category) => ({
                  value: category.id,
                  label: category.title,
                }))}
              value={data.value}
              readonly={!editMode}
              onChange={(e) => {
                const val = {
                  target: {
                    name: `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`,
                    value: e,
                  },
                };
                handleChange(val);
              }}
              label="Select Categories"
              errorMessage={
                submitCount > 0 &&
                errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]
                  ?.value &&
                "Select 1 or more Categories"
              }
            />
          );
        }
      } else {
        if (data.logic === Conditions.ProductCategory.Operators.Contains) {
          return (
            <DataPoint title="Contains" maxWidth="350px" data={data.value} />
          );
        }
        if (data.logic === Conditions.ProductCategory.Operators.IsEqualTo) {
          return (
            <DataPoint
              title="Categories"
              maxWidth="350px"
              data={data.value.map((category) => category.label).join(", ")}
            />
          );
        }
      }
    }

    if (data.condition === Conditions.Quantity.Name) {
      if (editMode) {
        if (data.logic === Conditions.Quantity.Operators.IsBetween) {
          return (
            <div className="quantity-between-inputs">
              <Input
                label="Greater Than or Equal"
                name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
                value={data.value}
                readonly={!editMode}
                onChange={(e) => onlyNumbers(e)}
                placeholder="Greater Than or Equal"
                errorMessage={
                  submitCount > 0 &&
                  errors.blocks?.[blockIndex]?.filterInfo?.filters?.[
                    filterIndex
                  ]?.value
                }
              />
              <Input
                label="Less Than or Equal"
                name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value2`}
                value={data.value2}
                readonly={!editMode}
                onChange={(e) => onlyNumbers(e)}
                placeholder="Less Than or Equal"
                errorMessage={
                  submitCount > 0 &&
                  errors.blocks?.[blockIndex]?.filterInfo?.filters?.[
                    filterIndex
                  ]?.value2
                }
              />
            </div>
          );
        } else {
          return (
            <Input
              label={conditionSelected ? "Quantity" : ""}
              name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
              value={data.value}
              readonly={!conditionSelected || !editMode}
              onChange={(e) => onlyNumbers(e)}
              placeholder={conditionSelected ? "Quantity" : ""}
              errorMessage={
                submitCount > 0 &&
                errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]
                  ?.value
              }
            />
          );
        }
      } else {
        if (data.logic === Conditions.Quantity.Operators.IsBetween) {
          return (
            <DataPoint
              title="Quantity"
              data={`${data.value} - ${data.value2}`}
            />
          );
        } else {
          return <DataPoint title="Quantity" data={data.value} />;
        }
      }
    }

    if (data.condition === Conditions.OrderMeshSKU.Name) {
      if (editMode) {
        return (
          <Input
            label={conditionSelected ? "OrderMesh SKU" : ""}
            name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
            value={data.value}
            readonly={!conditionSelected || !editMode}
            onChange={(e) => setFieldValue(e.target.name, e.target.value)}
            placeholder={conditionSelected ? data.logic == Operators.IsEqualTo ? "SKU (Separte multiple SKUs by comma)" : "SKU" : ""}
            errorMessage={
              submitCount > 0 &&
              errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]
                ?.value
            }
          />
        );
      } else {
        return <DataPoint title="OrderMesh SKU" data={data.value} />;
      }
    }

    if (data.condition === Conditions.CustomerShippingMethod.Name) {
      if (editMode) {
        return (
          <MultiSelect
            name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
            options={["Standard", "Expedited", "Overnight"]
              .map((o) => ({
                value: o,
                label: o,
              }))}
            value={data.value}
            readonly={!editMode}
            onChange={(e) => {
              const val = {
                target: {
                  name: `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`,
                  value: e,
                },
              };
              handleChange(val);
            }}
            label="Select shipping method"
            errorMessage={
              submitCount > 0 &&
              errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]
                ?.value &&
              "Select 1 or more shipping methods"
            }
          />
        );
      } else {
        return <DataPoint
          title="Customer shipping methods"
          maxWidth="350px"
          data={data.value.map((sm) => sm.label).join(", ")}
        />
      }
    }

    if (data.condition === Conditions.CustomerOrderTotal.Name) {
      if (editMode) {
        if (data.logic === Conditions.Quantity.Operators.IsBetween) {
          return (
            <div className="quantity-between-inputs">
              <Input
                label="Greater Than or Equal"
                name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
                value={data.value}
                readonly={!editMode}
                onChange={(e) => onlyNumbers(e)}
                placeholder="Greater Than or Equal"
                errorMessage={
                  submitCount > 0 &&
                  errors.blocks?.[blockIndex]?.filterInfo?.filters?.[
                    filterIndex
                  ]?.value
                }
              />
              <Input
                label="Less Than or Equal"
                name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value2`}
                value={data.value2}
                readonly={!editMode}
                onChange={(e) => onlyNumbers(e)}
                placeholder="Less Than or Equal"
                errorMessage={
                  submitCount > 0 &&
                  errors.blocks?.[blockIndex]?.filterInfo?.filters?.[
                    filterIndex
                  ]?.value2
                }
              />
            </div>
          );
        } else {
          return (
            <Input
              label={conditionSelected ? "Total" : ""}
              name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
              value={data.value}
              readonly={!conditionSelected || !editMode}
              onChange={(e) => onlyNumbers(e)}
              placeholder={conditionSelected ? "Total" : ""}
              errorMessage={
                submitCount > 0 &&
                errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]
                  ?.value
              }
            />
          );
        }
      } else {
        if (data.logic === Conditions.Quantity.Operators.IsBetween) {
          return (
            <DataPoint
              title="Total"
              data={`${data.value} - ${data.value2}`}
            />
          );
        } else {
          return <DataPoint title="Total" data={data.value} />;
        }
      }
    }

    if (editMode) {
      if (data.logic === Operators.IsBetween) {
        return (
          <div className="quantity-between-inputs">
            <Input
              label="Greater Than or Equal"
              name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
              value={data.value}
              readonly={!editMode}
              onChange={(e) => onlyNumbers(e)}
              placeholder="Greater Than or Equal"
              errorMessage={
                submitCount > 0 &&
                errors.blocks?.[blockIndex]?.filterInfo?.filters?.[
                  filterIndex
                ]?.value
              }
            />
            <Input
              label="Less Than or Equal"
              name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value2`}
              value={data.value2}
              readonly={!editMode}
              onChange={(e) => onlyNumbers(e)}
              placeholder="Less Than or Equal"
              errorMessage={
                submitCount > 0 &&
                errors.blocks?.[blockIndex]?.filterInfo?.filters?.[
                  filterIndex
                ]?.value2
              }
            />
          </div>
        );
      } else {
        if (data.condition === "-") {
          return (
            <Input
              label="&#8203;"
              name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
              value={data.value}
              disabled={true}
              onChange={handleChange}
              placeholder={conditionSelected ? "Value" : ""}
              errorMessage={
                submitCount > 0 &&
                errors.blocks?.[blockIndex]?.filterInfo?.filters?.[
                  filterIndex
                ]?.value
              }
            />
          );
        } else {
          return (
            <Input
              label={conditionSelected ? "Value" : "-"}
              name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
              value={data.value}
              readonly={!conditionSelected || !editMode}
              onChange={handleChange}
              placeholder={conditionSelected ? "Value" : ""}
              errorMessage={
                submitCount > 0 &&
                errors.blocks?.[blockIndex]?.filterInfo?.filters?.[
                  filterIndex
                ]?.value
              }
            />
          );
        }
      }
    } else {
      if (data.logic === Operators.IsBetween) {
        return (
          <DataPoint title="Value" data={`${data.value} - ${data.value2}`} />
        );
      } else {
        return <DataPoint title="Value" data={data.value} />;
      }
    }
  };

  const onlyNumbers = (event) => {
    const { name, value } = event.target;
    if (/^\d*$/.test(value)) {
      setFieldValue(name, value);
    }
  };

  const updateOnConditionChange = (e) => {
    if (e.target.value === Conditions.CustomField.Name) {
      e.target.value = "";
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.logic`,
        Conditions.CustomField.Operators.IsEqualTo
      );
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`,
        ""
      );
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value2`,
        ""
      );
    }

    if (e.target.value === Conditions.ShipToCountry.Name) {
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.logic`,
        Conditions.ShipToCountry.Operators.IsEqualTo
      );
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`,
        []
      );
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value2`,
        ""
      );
    }

    if (e.target.value === Conditions.ProductCategory.Name) {
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.logic`,
        Conditions.ProductCategory.Operators.IsEqualTo
      );
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`,
        []
      );
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value2`,
        ""
      );
    }

    if (e.target.value === Conditions.OrderMeshSKU.Name) {
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.logic`,
        Conditions.OrderMeshSKU.Operators.IsEqualTo
      );
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`,
        []
      );
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value2`,
        ""
      );
    }

    if (e.target.value === Conditions.Quantity.Name) {
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.logic`,
        Conditions.Quantity.Operators.IsLessThan
      );
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`,
        ""
      );
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value2`,
        ""
      );
    }

    if (e.target.value === Conditions.CustomerShippingMethod.Name) {
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.logic`,
        Conditions.CustomerShippingMethod.Operators.IsEqualTo
      );
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`,
        []
      );
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value2`,
        ""
      );
    }

    if (e.target.value === Conditions.CustomerOrderTotal.Name) {
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.logic`,
        Conditions.CustomerOrderTotal.Operators.IsEqualTo
      );
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`,
        ""
      );
      setFieldValue(
        `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value2`,
        ""
      );
    }
  }

  const updateOnOperatorChange = (e, data) => {
    if (data.condition === Conditions.ProductCategory.Name) {
      if (e.target.value === Conditions.ProductCategory.Operators.Contains) {
        data.value = "";
      }
      if (e.target.value === Conditions.ProductCategory.Operators.IsEqualTo) {
        data.value = [];
      }
    }

    if (data.condition === Conditions.OrderMeshSKU.Name) {
      if (e.target.value === Conditions.OrderMeshSKU.Operators.Contains) {
        data.value = "";
      }
      if (e.target.value === Conditions.ProductCategory.Operators.IsEqualTo) {
        data.value = [];
      }
    }
  }

  const conditionSelected = data.condition !== "-";

  return (
    <>
      <div className={`block-filter ${editMode ? "edit-mode" : ""}`}>
        <div className="column-1">
          {editMode && availableConditions.some((option) => option.value === data.condition) ? (
            <Dropdown
              label="Condition"
              name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.condition`}
              value={values.blocks[blockIndex].filterInfo.filters[filterIndex].condition}
              onChange={(e) => {
                updateOnConditionChange(e);
                handleChange(e);
                // updating the formik state and immediately validating the form causes it to miss the change.  Wait till the next render to validate.
                setTimeout(() => {
                  validateForm();
                }, 1);
              }}
              readonly={!editMode}
              options={getUniqueConditions()}
              errorMessage={submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]?.condition
              }
            />
          ) : (
            editMode && (
              <Input
                label="Condition"
                name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.condition`}
                value={values.blocks[blockIndex].filterInfo.filters[filterIndex].condition}
                onChange={handleChange}
                readonly={!editMode}
                placeholder="Condition"
                errorMessage={submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]?.condition
                }
              />
            )
          )}
          {!editMode && <DataPoint title="Condition" data={data.condition} />}
        </div>
        <div className="column-2">
          {editMode ? (
            <Dropdown
              label="Logic"
              name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.logic`}
              value={values.blocks[blockIndex].filterInfo.filters[filterIndex].logic}
              disabled={!conditionSelected}
              className="filter-logic"
              readonly={!editMode}
              onChange={(e) => {
                updateOnOperatorChange(e, data);
                handleChange(e);
              }}
              options={data.condition === "-" ? [] : getOperators(data.condition)}
              errorMessage={submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]?.logic}
            />
          ) : (
            <DataPoint title="Logic" enableClipboardCopy={false} data={data.logic}
            />
          )}
        </div>
        <div className="column-3">{getValueInput(data)}</div>
        {editMode && (
          <div className="row-options">
            <ButtonIcon icon={<PlusLg />} onClick={() => addFilter()} />
            <ButtonIcon icon={<Trash3 />} destructive={true} onClick={() => removeFilter(filterIndex)} />
          </div>
        )}
      </div>
    </>
  );
};

export default Filter;
