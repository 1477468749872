import React, { useState, useEffect } from 'react';
import { Editor } from '@monaco-editor/react';
import './index.scss';

function LambdaEditor({ value, error, onValueChanged, readOnly, placeholder }) {
  const [text, setText] = useState(value);

  useEffect(() => {
    setText(value);
  }, [value]);

  const handleEditorChange = (newValue) => {
    // Remove any newline characters from the newValue
    const singleLineValue = newValue.replace(/[\r\n]+/g, '');
    setText(singleLineValue);
    onValueChanged(singleLineValue);
  };

  const handleEditorMount = (editor, monaco) => {
    editor.addCommand(monaco.KeyCode.Enter, () => {});
  };

  const editorOptions = {
    overviewRulerLanes: 0,
    renderLineHighlight: 'none',
    cursorBlinking: 'blink',
    automaticLayout: true,
    minimap: { enabled: false },
    readOnly: readOnly,
    lineNumbers: "off",
    wordWrap: "on",
  };

  return (
    <div style={{ position: 'relative' }}>
      <Editor
        className="code-snippet-editor"
        options={editorOptions}
        height="100px"
        language="csharp"
        theme="light"
        value={text}
        onChange={handleEditorChange}
        onMount={handleEditorMount}
      />
      {!text && !readOnly && (
        <div className="editor-placeholder">{placeholder}</div>
      )}
      {error && (
        <div className="gooten-input">
          <div className="error">{error}</div>
        </div>
      )}
    </div>
  );
}

export default LambdaEditor;
