import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import './index.scss';

const ButtonMenu = ({
  className,
  label,
  labelHTML,
  size = 'small',           
  variant = 'primary',      
  direction = 'down',       
  options,
  width = null,             
  align = 'start',          
}) => {

  const style = width ? { width: `${width}px` } : {};

  return (
    <div className={`gooten-button-menu size-${size} variant-${variant} ${className || ''}`} style={style}>
      <Dropdown>
        <Dropdown.Toggle className="rows-per-page-dropdown" style={style}>
          {labelHTML ? labelHTML : (
            <>
              {label}
              {direction === 'down' ? <ChevronDown /> : <ChevronUp />}
            </>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu style={style} align={align}>
          {options.map((option) => (
            <Dropdown.Item
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              className={option.destructive ? 'destructive' : ''}
              onClick={option.onClick}
            >
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

ButtonMenu.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  labelHTML: PropTypes.object,
  size: PropTypes.oneOf(['slim', 'small', 'medium']),
  variant: PropTypes.oneOf(['primary', 'secondary']),
  direction: PropTypes.oneOf(['up', 'down']),
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  width: PropTypes.number,
  align: PropTypes.oneOf(['start', 'end']),
};

export default ButtonMenu;
