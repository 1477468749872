import { Card, Input, ButtonIcon, ToggleSwitch } from 'app/components'
import { PlusLg } from 'react-bootstrap-icons';
import Filter from './Filter';
import './index.scss';
import React from 'react';
import LambdaEditor from './LambdaEditor';
import { Conditions } from '../Utils';

const FilterInfo = props => {
  const {
    blockIndex,
    values,
    data,
    categories,
    editMode,
    errors,
    handleChange,
    submitCount,
    setFieldValue,
    validateForm,
  } = props;

  const addFilter = () => {
    let filterInfo = values.blocks[blockIndex].filterInfo;
    if (!filterInfo) {
      filterInfo = {
        filterName: '',
        filters: [],
      };
    }

    filterInfo.filters.push({
      condition: '-',
      logic: '',
      value: '',
      value2: '',
    });

    setFieldValue(`blocks[${blockIndex}].filterInfo`, filterInfo);
  }

  const removeFilter = (filterIndex) => {
    const updatedFilters = values.blocks[blockIndex].filterInfo.filters.filter((_, index) => index !== filterIndex);
    if (updatedFilters.length === 0) {
      setFieldValue(`blocks[${blockIndex}].filterInfo`, null);
      return;
    }
    setFieldValue(`blocks[${blockIndex}].filterInfo.filters`, updatedFilters);
  }

  const isCodeMode = () => {
    return values.blocks[blockIndex].filterInfo.filters[0].condition === Conditions.CustomCode.Name;
  }

  return (
    <Card className={`filter-component ${editMode && data ? 'no-bot-padding' : ''}`}>
      <Card.Header className={`filter-component-header ${editMode ? 'no-bot-padding' : ''} ${!data ? 'no-filters' : ''}`}>
        {!data && (
          <>
            <div className="filter-header">
              <div>Filters</div>
              <div className="optional-filter-message">Filters are optional.  They allow you specify rules on the block.</div>
            </div>
            {editMode && (
              <div className="section-options">
                <ButtonIcon
                  icon={<PlusLg />}
                  onClick={() => addFilter()}
                />
              </div>
            )}
          </>
        )}
        {!editMode && data && (
          <div>
            <div className="filter-name">{data.filterName}</div>
          </div>
        )}
        {editMode && data && (<>
          <Input
            label="Filter Name"
            className="filter-name-input"
            name={`blocks.${blockIndex}.filterInfo.filterName`}
            value={data.filterName}
            onChange={handleChange}
            readonly={!editMode}
            placeholder="Filter Name"
            errorMessage={submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filterName}
          />
          <div>
            <div className='settings-item-bool'>
              <ToggleSwitch
                isOn={values.blocks[blockIndex].filterInfo.filters[0].condition === Conditions.CustomCode.Name}
                onToggle={(toggle) => {
                  if (toggle === true) {
                    setFieldValue(
                      `blocks.${blockIndex}.filterInfo.filters`,
                      [{
                        condition: Conditions.CustomCode.Name,
                        logic: "",
                        value: "",
                        value2: ""
                      }]
                    );
                  } else {
                    setFieldValue(
                      `blocks.${blockIndex}.filterInfo.filters`,
                      [{
                        condition: "-",
                        logic: "",
                        value: "",
                        value2: ""
                      }]
                    );
                  }
                }}
              />
              <div className='settings-item-title'>Code mode</div>
            </div>
          </div>
        </>
        )}
      </Card.Header>
      {data && (
        <div className="filter-body">
          {data && (
            <div className="filters-container">
              {!isCodeMode() && data.filters.map((filter, filterIndex) => (
                <React.Fragment key={`filter-${filterIndex}`}>
                  <div>
                    <Filter
                      blockIndex={blockIndex}
                      filterIndex={filterIndex}
                      values={values}
                      data={filter}
                      categories={categories}
                      editMode={editMode}
                      addFilter={addFilter}
                      removeFilter={removeFilter}
                      errors={errors}
                      handleChange={handleChange}
                      submitCount={submitCount}
                      setFieldValue={setFieldValue}
                      validateForm={validateForm}
                    />
                  </div>
                </React.Fragment>
              ))}
              {isCodeMode() &&
                <div className="pb-3">
                  <LambdaEditor
                    placeholder={Conditions.CustomCode.Placeholder}
                    value={values.blocks[blockIndex].filterInfo.filters[0].value}
                    error={submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filters[0]?.value ? "Please provide a non-empty code input" : null}
                    readOnly={!editMode}
                    onValueChanged={(text) => {
                      setFieldValue(
                        `blocks.${blockIndex}.filterInfo.filters`,
                        [{
                          condition: Conditions.CustomCode.Name,
                          logic: "-",
                          value: text,
                          value2: "-"
                        }]
                      );
                    }}
                  />
                </div>
              }
              {data.filters.length === 0 && (
                <div className="malformed-filter-expression">Filter Expression appears malformed : {data.filterExpression}</div>
              )}
            </div>
          )}
        </div>
      )}
    </Card>
  )
}

export default FilterInfo;