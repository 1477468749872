import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getFacilityDetails } from 'app/store/actions/vendor';
import { Card, ButtonIcon, StatusChip, IconData, Button, MessageBar, LoadingAnimation, Link, Pagination } from 'app/components';
import { ThreeDotsVertical, ChevronDown, ChevronLeft, Truck, XCircleFill, Person, SuitcaseLg, Telephone, Envelope, GeoAlt, SlashCircle } from 'react-bootstrap-icons';
import { usePermission } from 'app/permissions';
import PackageCard from './PackageCard';
import OrderItem from '../OrderItem';
import './index.scss';

const ShipmentCard = props => {
  const {
    orderId,
    shipment,
    menuState,
    toggleMenu,
    showEditTrackingModal,
    showCancelShipmentModal,
    showCancelOrderItemModal,
    showCarrierLabelModal,
    showChangeVendorModal,
    showRejectShipmentModal,
    showUnassignItemsModal,
    showFacilityInfoModal,
    showChangeShipmentStatusModal,
    showTrackingInfoModal,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [facilityDetails, setFacilityDetails] = useState(null);
  const [currentPageCanceled, setCurrentPageCanceled] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [canceledMenuState, setCanceledMenuState] = useState(true);

  // get user permissions
  const canUpdateShipments = usePermission('order', 'update');

  const shipmentOptions = () => [
    { value: 'Update Status', label: 'Update Status', onClick: () => { showChangeShipmentStatusModal(shipment) } },
    { value: 'Edit Tracking', label: 'Edit Tracking', onClick: () => { showEditTrackingModal({ shipment }) } },
    { value: 'Reroute All Items', label: 'Reroute All Items', onClick: () => showChangeVendorModal({ shipmentId: shipment.id, shipmentShortId: shipment.shortId }) },
    { value: 'Unassign All Items', label: 'Unassign All Items', onClick: () => showUnassignItemsModal({ shipmentId: shipment.id, itemIds: shipment.orderItemIDs, isAllItems: true }) },
    { value: 'Facility Information', label: 'Facility Information', onClick: () => { showFacilityInfoModal({ vendorId: shipment.vendorId }) } },
    { value: 'Reject Shipment', label: 'Reject Shipment', destructive: true, onClick: () => { showRejectShipmentModal(shipment) } },
    ...(shipment.shipmentStatus !== 'Canceled' ? [{ value: 'Cancel Shipment', label: 'Cancel Shipment', destructive: true, onClick: () => showCancelShipmentModal(shipment) }] : []),
  ];

  // Extract canceled items from packages
  const canceledItems = useMemo(() => {
    return shipment.packages.flatMap(pkg =>
      pkg.orderItemIDs
        .map(itemId => shipment.orderItems.find(item => item.id === itemId))
        .filter(item => item?.status === 'canceled')
    );
  }, [shipment.packages, shipment.orderItems]);

  // create a deep copy of the packages object in the shipments to avoid mutating the original object
  let updatedPackages = JSON.parse(JSON.stringify(shipment.packages));

  // remove the canceled items from the updated packages object
  updatedPackages.forEach(pkg => {
    pkg.orderItemIDs = pkg.orderItemIDs.filter(itemId => !canceledItems.some(item => item.id === itemId));
  });

  // if any packages are empty, remove them from the updated packages object
  updatedPackages = updatedPackages.filter(pkg => pkg.orderItemIDs.length > 0);

  // Calculate displayed canceled items for pagination
  const displayedCanceledItems = useMemo(() => {
    const startIndex = (currentPageCanceled - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return canceledItems.slice(startIndex, endIndex);
  }, [canceledItems, currentPageCanceled, pageSize]);

  const totalCanceledItems = canceledItems.length;

  const numItems = shipment.packages.reduce((total, pkg) => total + pkg.orderItemIDs.length, 0);

  const facilityDetailsCallback = (facilityDetails) => {
    setFacilityDetails(facilityDetails?.facility);
  };

  const facilityDetailsErrorCallback = () => {
    setFacilityDetails('failed');
  };

  const toggleAdditionalInfo = () => {
    if (showAdditionalInfo) {
      // closing additional info.  If the failure message is showing, reset it
      if (facilityDetails === 'failed') {
        setFacilityDetails(null);
      }
    } else {
      if (shipment.vendorId && shipment.facilityId && !facilityDetails) {
        dispatch(getFacilityDetails({ vendorId: shipment.vendorId, facilityId: shipment.facilityId, cb: facilityDetailsCallback, cbError: facilityDetailsErrorCallback}));
      }
    }
    setShowAdditionalInfo(!showAdditionalInfo);
  };

  const address = facilityDetails ? [
    facilityDetails.address?.line1,
    facilityDetails.address?.line2,
    facilityDetails.address?.city,
    facilityDetails.address?.state ? `${facilityDetails.address?.state},` : '',
    facilityDetails.address?.zip,
    facilityDetails.address?.countryCode
  ].filter(Boolean).join(' ') : null;

  return (
    <Card className="shipment-card">
      <Card.Header>
        <div className={`shipment-header ${menuState[shipment.shortId] && 'collapsed'}`}>
          <div className="shipment-header-body">
            <div className="id-status">
              <div>{`Shipment ${shipment.shortId}`}</div>
              <StatusChip type="shipment" size="slim" status={shipment.shipmentStatus} darkMode={true} />
            </div>
            <div className="shipment-data-and-options">
              {shipment.vendorName && (
                <IconData
                  label={shipment.vendorName}
                  icon={<Truck />}
                  onClick={shipment.vendorId ? () => navigate("/admin/vendors/" + shipment.vendorId) : undefined}
                  darkMode={true}
                />
              )}
              {updatedPackages.length === 1 && updatedPackages[0].carrierTrackingNumber && (
                <IconData
                  label="View Tracking Info"
                  icon={<GeoAlt />}
                  onClick={() => showTrackingInfoModal(updatedPackages[0])}
                  darkMode={true}
                />
              )}
              <div className="shipment-options">
                {canUpdateShipments && (
                  <ButtonIcon
                    icon={<ThreeDotsVertical />}
                    options={shipmentOptions()}
                    darkMode={true}
                  />
                )}
                {numItems > 0 && (
                  <ButtonIcon
                    className="collapse-menu"
                    icon={menuState[shipment.shortId] ? <ChevronLeft /> : <ChevronDown />}
                    onClick={() => toggleMenu(shipment.shortId)}
                    darkMode={true}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="additional-shipment-info" onClick={toggleAdditionalInfo}>
            {shipment.orderExternalId && (
              <div className="info-field">
                <div>External Shipment ID</div>
                <div className="facility-name">{shipment.orderExternalId}</div>
              </div>
            )}
            <div className="info-field">
              <div>Facility Name</div>
              <div className="facility-name">{shipment.facilityName}</div>
              {showAdditionalInfo ? <ChevronDown /> : <ChevronLeft />}
            </div>
            <div className={`vendor-facility-stats ${showAdditionalInfo ? 'show' : 'hide'}`}>
              {facilityDetails && facilityDetails !== 'failed' && (
                <>
                  {facilityDetails.contacts?.[0]?.name && (
                    <div className="vendor-facility-contact-item">
                      <Person />
                      <span className="contact-detail">{facilityDetails.contacts[0].name}</span>
                    </div>
                  )}
                  {facilityDetails.contacts?.[0]?.role && (
                    <div className="vendor-facility-contact-item">
                      <SuitcaseLg />
                      <span className="contact-detail">{facilityDetails.contacts[0].role}</span>
                    </div>
                  )}
                  {facilityDetails.contacts?.[0]?.phone && (
                    <div className="vendor-facility-contact-item">
                      <Telephone />
                      <span className="contact-detail">{facilityDetails.contacts[0].phone}</span>
                    </div>
                  )}
                  {facilityDetails.contacts?.[0]?.email && (
                    <div className="vendor-facility-contact-item">
                      <Envelope />
                      <span className="contact-detail">{facilityDetails.contacts[0].email}</span>
                    </div>
                  )}
                  {address && (
                    <div className="vendor-facility-contact-item">
                      <GeoAlt />
                      <span className="contact-detail">{address}</span>
                    </div>
                  )}
                  {!address && !facilityDetails.contacts?.[0]?.name && !facilityDetails.contacts?.[0]?.role && !facilityDetails.contacts?.[0]?.phone && !facilityDetails.contacts?.[0]?.email && (
                    <div className="vendor-facility-contact-item">
                      No contact information available for this facility
                    </div>
                  )}
                  <div className="view-facility-link">
                    <Link
                      label="View Full Details"
                      size="small"
                      url={`/admin/vendors/${shipment.vendorId}/facility/${shipment.facilityId}/details`}
                    />
                  </div>
                </>
              )}
              {facilityDetails === 'failed' && (
                <div className="vendor-facility-contact-item">
                  Facility details failed to load
                </div>
              )}
              {facilityDetails === null && (
                <div className="vendor-data-loading">
                  <LoadingAnimation fullscreen={false} />
                </div>
              )}
            </div>
          </div>
          {shipment.shipmentStatus === 'FulfillmentError' && (
            <MessageBar color="red" darkMode icon={<XCircleFill />}>
              Shipment failed submission to the vendor
              <Button
                label="Resubmit to Vendor"
                destructive
                size="small"
                onClick={() => console.log("Not implemented yet")}
              />
            </MessageBar>
          )}
        </div>
      </Card.Header>
      {!menuState[shipment.shortId] && (
        <Card.Body>
          {updatedPackages.map((orderPackage) => (
            <PackageCard
              key={orderPackage.id}
              orderId={orderId}
              shipment={shipment}
              orderPackage={orderPackage}
              numPackages={updatedPackages.length}
              showCancelOrderItemModal={showCancelOrderItemModal}
              showCarrierLabelModal={showCarrierLabelModal}
              showChangeVendorModal={showChangeVendorModal}
              showUnassignItemsModal={showUnassignItemsModal}
            />
          ))}
          {/* Canceled Items Section */}
          {totalCanceledItems > 0 && (
            <div className={`canceled-items-section ${updatedPackages.length === 0 && 'hide-header'}`}>
              {updatedPackages.length > 0 && (
                <div className="canceled-items-top-bar">
                  <div className="canceled-items-title"><SlashCircle />Canceled Items</div>
                <ButtonIcon
                  icon={canceledMenuState ? <ChevronDown /> : <ChevronLeft />}
                  onClick={() => setCanceledMenuState(!canceledMenuState)}
                  />
                </div>
              )}
              {canceledMenuState && (
                <>
                  {displayedCanceledItems.map(item => (
                    <OrderItem
                      key={item.id}
                      orderId={orderId}
                      itemInfo={item}
                      showCancelOrderItemModal={showCancelOrderItemModal}
                    />
                  ))}
                  {totalCanceledItems > pageSize && (
                    <div className="pagination-container">
                      <Pagination
                        totalItems={totalCanceledItems}
                        pageSizes={[5, 10, 20, 50]}
                        hideBelow={5}
                        currentPage={currentPageCanceled}
                        pageSize={pageSize}
                        onChange={(page, newPageSize) => {
                          setCurrentPageCanceled(page);
                          setPageSize(newPageSize);
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          {shipment.packages.length === 0 && (
            <div>No packages found in shipment</div>
          )}
        </Card.Body>
      )}
    </Card>
  );
}

export default ShipmentCard;