import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class UserServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.UserMS.Url
    });

    SetupInterceptors(this.api);
  }

  signinUser = async (username, password) => {
    try {
      const data = JSON.stringify({ username, password });
      const response = await this.api.post('/v1/login?source=portal', data);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  getUsers = async (searchString, status, sortBy, desc, currentPage, pageSize) => {
    try {
      let queryString = `/v1/search?page=${currentPage}&pageSize=${pageSize}`;
      if (searchString) {
        queryString += `&search=${encodeURIComponent(searchString)}`;
      }
      if (status) {
        queryString += `&status=${status}`;
      }
      if (sortBy) {
        queryString += `&sortBy=${sortBy}`;
      }
      if (desc) {
        queryString += `&desc=${desc}`;
      }

      const response = await this.api.get(queryString);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  getUserById = async (userId) => {
    try {
      const response = await this.api.get(`/v1/${userId}?source=portal`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  createUser = async (values) => {
    const data =  {
      "firstName": values.firstName,
      "lastName": values.lastName,
      "email" : values.email,
      "password" : values.password,
    };

    try {
      const response = await this.api.post(`/v1`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.errors[0].errorMessage.toString() || 'Create User Failed');
    }
  };

  updateUser  = async (userId, values) => {
    const data =  {
      "firstName": values.firstName,
      "lastName": values.lastName,
      "email" : values.email,
    };
    
    try {
      const response = await this.api.patch(`/v1/${userId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Update User Failed');
    }
  };

  assignRole  = async (userId, role) => {
    try {
      const data = {
        "roleId": role
      };

      const response = await this.api.post(`/v1/${userId}/role`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error assigning role to user');
    }
  };
  
  unassignRole  = async (userId, role) => {
    try {
      const data = {
        "roleId": role
      };

      const response = await this.api.delete(`/v1/${userId}/role`,  { data: data });
      return response.data;
    } catch (error) {
      throw new Error('Error unassigning role to user');
    }
  };

  assignUserPermissions  = async (userId, roleId, permissions) => {
    try {
      const response = await this.api.put(`/v1/${userId}/roles/${roleId}/permissions?source=portal`, permissions);

      return response.data;
    } catch (error) {
      throw new Error('Error assigning role permissions to user');
    }
  };

  resetPassword = async (userId) => {
    try {
      const response = await this.api.post(`/v1/${userId}/reset-password`);
      return response.data;
    } catch (error) {
      throw new Error('Failed To Reset Password');
    }
  };

  forgotPassword = async (email) => {
    try {
      const data = {
        "email": email
      };
      const response = await this.api.post(`/v1/forgot-password`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.errors[0].errorMessage);
    }
  };

  updateForgotPassword = async (token, password) => {
    try {
      const data = {
        "password": password
      };
      const response = await this.api.post(`/v1/reset-password/${token}`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.errors[0].errorMessage);
    }
  };

  getRoles = async () => {
    try {
      const response = await this.api.get('/v1/roles');
      return response.data;
    } catch (error) {
      throw new Error('Error fetching roles from API');
    }
  };  

  deleteUser = async (userId) => {
    try {
      const response = await this.api.delete(`/v1/${userId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error deleting user');
    }
  };

  updateUserStatus = async (userId, enabled) => {
    const data = {
      "enabled": enabled
    };

    try {
      const response = await this.api.patch(`/v1/${userId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error activating/deactivating user');
    }
  }

  getRoleWithPermissions = async () => {
    try {
      const response = await this.api.get(`/v1/roles/permissions?source=portal`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  searchRoles = async (searchString, sortBy, desc, currentPage, pageSize) => {
    try {
      let queryString = `/v1/roles/search?page=${currentPage}&pageSize=${pageSize}&source=portal`;
      if (searchString) {
        queryString += `&search=${encodeURIComponent(searchString)}`;
      }
      if (sortBy) {
        queryString += `&sortBy=${sortBy}`;
      }
      if (desc) {
        queryString += `&desc=${desc}`;
      }
      const response = await this.api.get(queryString);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  deleteRole = async (roleId) => {
    try {
      const response = await this.api.delete(`/v1/roles/${roleId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error deleting role');
    }
  };

  getRoleById = async (roleId) => {
    try {
      const response = await this.api.get(`/v1/roles/${roleId}/permissions?source=portal`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  createRole = async (values) => {
    const data =  {
      "name": values.name
    };

    try {
      const response = await this.api.post(`/v1/roles`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.errors[0].errorMessage.toString() || 'Create Role Failed');
    }
  };

  updateRole = async (roleId, permissions) => {
    try {
      const response = await this.api.put(`/v1/roles/${roleId}/permissions?source=portal`, permissions);

      return response.data;
    } catch (error) {
      throw new Error('Error updating role permissions');
    }
  };

  getResources = async () => {
    try {
      const response = await this.api.get(`/v1/resources?source=portal`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  updateRoleName = async (roleId, name) => {
    const data = {
      name
    };
    const errMsg = 'Error updating role name';

    try {
      const response = await this.api.put(`/v1/roles/${roleId}/name`, data);
      if (response.status !== 204){
        throw new Error(errMsg);
      }
    } catch (error) {
      throw new Error(errMsg);
    }
  }

  addAttributesToUser = async (userId, type, entity, entityId, associatedIDs) => {
    const data = {
      type, entity, entityId, associatedIDs
    };
    const errMsg = 'Error adding attributes to user';

    try {
      const response = await this.api.put(`/v1/${userId}/attributes`, data);
      if (response.status !== 204) {
        throw new Error(errMsg);
      }
    } catch (error) {
      throw new Error(errMsg);
    }
  }

  removedAttributesToUser = async (userId, attributes) => {
    const errMsg = 'Error adding attributes to user';
    try {
      const response = await this.api.delete(`/v1/${userId}/attributes`, { data: attributes });
      if (response.status !== 204) {
        throw new Error(errMsg);
      }
    } catch (error) {
      throw new Error(errMsg);
    }
  }

  getGroups = async () => {
    try {
      const response = await this.api.get(`/v1/groups`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching organisation groups');
    }
  };

  createClient = async (values) => {
    const data =  {
      "Role": values.role,  // "vendor" || "merchant"
      "Id": values.id       // vendor or merchant id
    };

    try {
      const response = await this.api.post(`/v1/clients`, data);
      return response.data;
    } catch (error) {
      throw new Error('Create Service Client Failed');
    }
  };

  findClient = async (role, id) => {
    //role: "vendor" || "merchant"
    //id: vendor or merchant id
    try {
      const response = await this.api.get(`/v1/clients/find/${role}/${id}`);
      // Note: on UI we should show clientId from responce (has format "{role}-{id}-client") not GUID id
      // it should be used to obtain token
      return response.data;
    } catch (error) {
      throw new Error('Error fetching client data from API');
    }
  };

  regenerateClientSecret = async (id) => {
    //id: client id GUID from findClient method (not clientId)
    try {
      const response = await this.api.put(`/v1/clients/${id}/secret`, {});
      return response.data;
    } catch (error) {
      throw new Error('Error generating client secret from API');
    }
  };

  deleteClient = async (id) => {
    //id: client id GUID from findClient method (not clientId)
    try {
      const response = await this.api.delete(`/v1/clients/${id}`);
      return response.data;
    } catch (error) {
      throw new Error('Error deleting Client');
    }
  };

}

export default new UserServices();
