import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { ButtonIcon, Card, TabbedMenu, IconData } from 'app/components';
import { getVendorDetails, getVendorConfig } from 'app/store/actions/vendor';
import { vendorDetailsSelector, vendorDetailsErrorsSelector } from 'app/store/selectors/vendor';
import DetailsView from './DetailsView';
import ProductsView from './ProductsView';
import FacilitiesView from './FacilitiesView';
import HistoryView from './HistoryView';
import AuthView from './AuthView';
import { ArrowLeft, Upc, GeoAlt } from 'react-bootstrap-icons';
import { usePermission } from 'app/permissions';
import './index.scss';
import VendorFtpInventorySync from './FtpInventorySync';

const VendorDetails = () => {
  const { vendorId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vendorDetails = useSelector(vendorDetailsSelector);
  const vendorDetailsErrorsFound = useSelector(vendorDetailsErrorsSelector);

  // get user permissions
  const canViewVendor = usePermission('vendor', 'search');
  const canManageClient = usePermission('users', 'clients_manage');

  const tabs = [
    ...(canViewVendor ? [{
      path: 'facilities',
      label: 'Vendor Facilities',
      content: FacilitiesView,
    }] : []),
    ...(canViewVendor ? [{
      path: 'products',
      label: 'All Products',
      content: ProductsView,
    }] : []),
    {
      path: 'history',
      label: 'general.history',
      content: HistoryView,
    },
    {
      path: 'details',
      label: 'Vendor Details',
      content: DetailsView,
    },
    {
      path: 'ftp',
      label: 'FTP',
      content: VendorFtpInventorySync,
    },
    ...(canManageClient ? [{
      path: 'auth',
      label: 'Authentication',
      content: AuthView,
    }] : []),
  ];

  useEffect(() => {
    if (vendorId && (!vendorDetails || vendorDetails.vendorId !== vendorId)) {
      dispatch(getVendorDetails({ vendorId }));
      dispatch(getVendorConfig(vendorId));
    }
  }, [vendorId, vendorDetails?.id, dispatch]);

  const displayHeader = () => {
    if (vendorId) {
      if (vendorDetails && vendorDetails.name) {
        return vendorDetails.name;
      } else if (vendorDetailsErrorsFound) {
        return (
          <span>Vendor Details</span>
        );
      } else {
        return (
          <span>&nbsp;</span>
        );
      }
    } else {
      return 'Create New Vendor';
    }
  }

  const filteredTabs = vendorDetails?.facilities?.length > 0 ? tabs : tabs.filter(tab => tab.path !== 'products');

  const address = vendorDetails ? [
    vendorDetails.address?.line1,
    vendorDetails.address?.line2,
    vendorDetails.address?.city,
    vendorDetails.address?.state ? `${vendorDetails.address?.state},` : '',
    vendorDetails.address?.zip,
    vendorDetails.address?.countryCode
  ].filter(Boolean).join(' ') : '';

  return (
    <div className="vendor-details">
      <Card className={`header-card ${!vendorDetails && vendorId ? 'loading' : ''}`}>
        <div className="vendor-name">
          <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/admin/vendors')} />
          {displayHeader()}
        </div>
        {vendorDetails && (
          <div className="header-data">
            {(vendorDetails.shortId || vendorDetails.vendorId) && (
              <IconData label={vendorDetails.shortId || vendorDetails.vendorId} icon={<Upc />} />
            )}
            {address && (
              <IconData label={address} icon={<GeoAlt />} />
            )}
          </div>
        )}
        <div className="action-buttons-placeholder"></div>
      </Card>
      <TabbedMenu tabs={filteredTabs} showTabs={Boolean(vendorId)} vendorId={vendorId} />
    </div>
  )
}

export default VendorDetails;