import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { facilityDetailsSelector, facilityDetailsLoadingSelector, facilityDetailsErrorsSelector } from 'app/store/selectors/vendor';
import { addFacility, updateFacility } from 'app/store/actions/vendor';
import { Card, Button, Input, Dropdown, DataPoint, MessageBar } from 'app/components';
import { convertCountryCodeToName } from 'app/utils';
import { CountryList } from 'app/constants';
import './index.scss';

const DetailsView = props => {
  const { vendorId, facilityId } = props;
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(facilityDetailsLoadingSelector);
  const facilityDetails = useSelector(facilityDetailsSelector);
  const facilityDetailsErrorsFound = useSelector(facilityDetailsErrorsSelector);

  useEffect(() => {
    if (!facilityDetails) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [facilityDetails]);

  const onFacilityAdded = () => {
    navigate(`/admin/vendors/${vendorId}/facilities`);
  }

  const onFacilityUpdated = () => {
    setEditMode(false);
  }

  return (
    <div className="facility-details-view">
      <Formik
        enableReinitialize
        initialValues={{
          facilityId: facilityDetails?.facilityId || '',
          facilityName: facilityDetails?.name || '',
          address1: facilityDetails?.address?.line1 || '',
          address2: facilityDetails?.address?.line2 || '',
          city: facilityDetails?.address?.city || '',
          state: facilityDetails?.address?.state || '',
          zipPostalCode: facilityDetails?.address?.zip || '',
          countryCode: facilityDetails?.address?.countryCode || 'US',
          contactName: facilityDetails?.contacts?.[0]?.name || '',
          role: facilityDetails?.contacts?.[0]?.role || '',
          phoneNumber: facilityDetails?.contacts?.[0]?.phone || '',
          email: facilityDetails?.contacts?.[0]?.email || '',
        }}
        validationSchema={() =>
          object().shape({
            facilityName: string().required('Enter a valid Facility Name'), // required
            address1: string()
              .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 1') // address
              .matches(/[\x20-\x7F]+/, 'Enter a valid Address 1') // onlyEnglish
              .max(35, 'Please limit to 35 characters')
              .required('Enter a valid Address 1'), // required
            address2: string().when(['address1', 'countryCode'], (address1, countryCode) => {
              if (countryCode !== 'US') {
                if (address1) {
                  return string()
                    .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                    .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                    .max(
                      35 - address1.length,
                      'Please limit to 35 characters for Address 1 + Address 2'
                    );
                } else {
                  return string()
                    .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                    .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                    .max(35, 'Please limit to 35 characters for Address 1 + Address 2');
                }
              } else {
                return string()
                  .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                  .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                  .max(35, 'Please limit to 35 characters');
              }
            }),
            city: string()
              .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid City') // address
              .matches(/[\x20-\x7F]+/, 'Enter a valid City') // onlyEnglish
              .required('Enter a valid City'), // required
            state: string()
              .when('countryCode', {
                is: (countryCode) => ['CA', 'US'].includes(countryCode),
                then: () => string().required('Enter a valid State/Province'),
                otherwise: () => string()
              })
              .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid State/Province')
              .matches(/[\x20-\x7F]+/, 'Enter a valid State/Province'),
            zipPostalCode: string().when('countryCode', {
              is: countryCode => countryCode === 'US',
              then: () => string()
                .matches(/^[a-zA-Z\d-]*$/, 'Enter a valid zip code') // zipcode
                .required('Enter a valid zip code'),
              otherwise: () => string().when('countryCode', {
                is: countryCode => countryCode === 'CA',
                then: () => string()
                  .matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code') // postalcode
                  .required('Enter a valid postal code'),
                otherwise: () => string().matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code') // postalcode
              })
            }),
            countryCode: string().required('Enter a Country'), // required
            contactName: string(),
            role: string(),
            phoneNumber: string(),
            email: string().email('Enter a valid Email'),
          })
        }
        onSubmit={async (values) => {
          const data = {
            "name": values.facilityName,
            "address": {
              "line1": values.address1,
              "line2": values.address2,
              "city": values.city,
              "state": values.state,
              "zip": values.zipPostalCode,
              "countryCode": values.countryCode,
            },
            "contacts": [{
              "name": values.contactName,
              "role": values.role,
              "phone": values.phoneNumber,
              "email": values.email,
            }],
          };

          if (facilityDetails) {
            // update existing facility
            dispatch(updateFacility({ vendorId, facilityId, data, cb: onFacilityUpdated }));
          } else {
            // create new facility
            dispatch(addFacility({ vendorId, data, cb: onFacilityAdded }));
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          submitCount,
        }) => (
          <Card className={`facility-details-card ${editMode && !facilityDetailsErrorsFound ? 'edit-mode' : 'view-mode'}`}>
            <Card.Header>
              Facility Details
              {!editMode && facilityDetails && !facilityDetailsErrorsFound && (
                <Button
                  variant="primary"
                  size="small"
                  label="Edit Facility Details"
                  onClick={() => setEditMode(true)}
                />
              )}
              {editMode && !facilityDetailsErrorsFound && (
                <div className={`action-buttons ${!facilityDetails ? 'new-facility' : ''}`}>
                  <Button
                    variant="secondary"
                    size={!facilityDetails ? "medium" : "small"}
                    label="Cancel"
                    disabled={loading}
                    onClick={() => !facilityDetails ? navigate(`/admin/vendors/${vendorId}/facilities`) : setEditMode(false)}
                  />
                  <Button
                    variant="primary"
                    size={!facilityDetails ? "medium" : "small"}
                    label={!facilityDetails ? 'Add Facility' : 'Update'}
                    disabled={loading}
                    onClick={() => (loading ? null : handleSubmit())}
                  />
                </div>
              )}
            </Card.Header>
            <Card.Body>
              {!editMode && facilityDetails && !facilityDetailsErrorsFound && (
                <>
                  <div className={`facility-information ${editMode ? 'edit-mode' : 'view-mode'}`}>
                    <DataPoint title="Name" data={facilityDetails.name} />
                    <DataPoint title="Address" data={`${facilityDetails.address?.line1}${facilityDetails.address?.line2 ? ' ' + facilityDetails.address.line2 : ''} ${facilityDetails.address?.city}, ${facilityDetails.address?.state} ${convertCountryCodeToName(facilityDetails.address?.countryCode)} ${facilityDetails.address?.zip}`} />
                  </div>
                  <div className="facility-contact-information">
                    Facility Contact Information
                  </div>
                  <div className={`facility-information mb-0 ${editMode ? 'edit-mode' : 'view-mode'}`}>
                    <DataPoint title="Contact Name" data={facilityDetails.contacts[0]?.name || '-'} />
                    <DataPoint title="Role" data={facilityDetails.contacts[0]?.role || '-'} />
                    <DataPoint title="Phone Number" data={facilityDetails.contacts[0]?.phone || '-'} />
                    <DataPoint title="Email" data={facilityDetails.contacts[0]?.email || '-'} />
                  </div>
                </>
              )}
              {editMode && !facilityDetailsErrorsFound && (
                <form onSubmit={handleSubmit}>
                  <div className={`facility-information ${editMode ? 'edit-mode' : 'view-mode'}`}>
                    <Input
                      label="Facility Name"
                      name="facilityName"
                      value={values.facilityName}
                      onChange={handleChange}
                      placeholder="Facility Name"
                      errorMessage={submitCount > 0 && errors.facilityName}
                    />
                  </div>
                  <div className="facility-address-information">
                    Address Information
                  </div>
                  <div className={`facility-information ${editMode ? 'edit-mode' : 'view-mode'}`}>  
                    <Input
                      label="Address Line 1"
                      name="address1"
                      value={values.address1}
                      onChange={handleChange}
                      placeholder="Street Address"
                      errorMessage={submitCount > 0 && errors.address1}
                    />
                    <Input
                      label="Address Line 2"
                      name="address2"
                      value={values.address2}
                      onChange={handleChange}
                      placeholder="Apt, Suite, unit, building, floor, etc"
                      errorMessage={submitCount > 0 && errors.address2}
                    />
                    <Input
                      label="City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      placeholder="City"
                      errorMessage={submitCount > 0 && errors.city}
                    />
                    <Input
                      label="State / Province"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      placeholder="State / Province"
                      errorMessage={submitCount > 0 && errors.state}
                    />
                    <Input
                      label="Zip / Postal Code"
                      name="zipPostalCode"
                      value={values.zipPostalCode}
                      onChange={handleChange}
                      placeholder="Zip / Postal Code"
                      errorMessage={submitCount > 0 && errors.zipPostalCode}
                    />
                    <Dropdown
                      label="Country"
                      name="countryCode"
                      value={values.countryCode}
                      onChange={handleChange}
                      options={CountryList.map(country => (
                        { value: country.CountryCode, label: `${country.CountryCode} - ${country.Name}` }
                      ))}
                      errorMessage={submitCount > 0 && errors.countryCode}
                    />
                  </div>
                  <div className="facility-contact-information">
                    Facility Contact Information
                  </div>
                  <div className={`facility-information ${editMode ? 'edit-mode' : 'view-mode'}`}>
                    <Input
                      label="Contact Name"
                      name="contactName"
                      value={values.contactName}
                      onChange={handleChange}
                      placeholder="Contact Name"
                      errorMessage={submitCount > 0 && errors.contactName}
                    />
                    <Input
                      label="Role"
                      name="role"
                      value={values.role}
                      onChange={handleChange}
                      placeholder="Role"
                      errorMessage={submitCount > 0 && errors.role}
                    />
                    <Input
                      label="Phone Number"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      placeholder="Phone Number"
                      errorMessage={submitCount > 0 && errors.phoneNumber}
                    />
                    <Input
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      placeholder="Email"
                      errorMessage={submitCount > 0 && errors.email}
                    />
                  </div>
                </form>
              )}
              {facilityDetailsErrorsFound && (
                <MessageBar color="yellow">
                  An error occurred while fetching data
                </MessageBar>
              )}
            </Card.Body>
          </Card>
        )}
      </Formik>
    </div>
  )
}

export default DetailsView;