import { put, takeEvery, call, all, takeLatest } from "redux-saga/effects";
import UserServices from "app/services/userServices";
import { toast } from "react-toastify";

import {
  createClient,
  createClientSuccess,
  createClientFailure,
  getClient,
  getClientSuccess,
  getClientFailure,
  generateSecret,
  generateSecretSuccess,
  generateSecretFailure,
  deleteClient,
  deleteClientSuccess,
  deleteClientFailure,
} from "app/store/actions/client";

function* fetchClient(action) {
  try {
    const { entityType, entityId } = action.payload;
    const data = yield call([UserServices, UserServices.findClient], entityType, entityId);
    yield put(getClientSuccess(data || {}));
  } catch (error) {
    yield put(getClientFailure(error?.message || "Fetching client failed"));
  }
}

function* doCreateClient(action) {
  try {
    const resp = yield call([UserServices, UserServices.createClient], action.payload);
    yield put(createClientSuccess(resp));
  } catch (error) {
    yield put(createClientFailure(error?.message));
    toast.error(error?.message || "Creating client failed", {
      theme: "colored",
    });
  }
}

function* doGenerateSecret(action) {
  try {
    const resp = yield call([UserServices, UserServices.regenerateClientSecret], action.payload);
    yield put(generateSecretSuccess(resp));
  } catch (error) {
    yield put(generateSecretFailure(error?.message));
    toast.error(error?.message || "Generating secret failed", {
      theme: "colored",
    });
  }
}

function* doDeleteClient(action) {
  try {
    yield call([UserServices, UserServices.deleteClient], action.payload);
    yield put(deleteClientSuccess({}));
    toast.success("Client deleted successfully", {
      theme: "colored",
    });
  } catch (error) {
    yield put(deleteClientFailure(error?.message));
    toast.error(error?.message || "Deleting client failed", {
      theme: "colored",
    });
  }
}

function* watchData() {
  yield takeLatest(getClient.toString(), fetchClient);
  yield takeEvery(createClient.toString(), doCreateClient);
  yield takeEvery(generateSecret.toString(), doGenerateSecret);
  yield takeEvery(deleteClient.toString(), doDeleteClient);
}

export default function* rootSaga() {
  yield all([watchData()]);
}
