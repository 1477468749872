import React from 'react';
import { ClientManager } from 'app/components'

const AuthView = props => {
  const { vendorId } = props;

  return (
    <div className="vendor-auth-view">
      <ClientManager entityType="vendor" entityId={vendorId}></ClientManager>
    </div>
  )
}

export default AuthView;