import React, { useState, useMemo } from 'react';
import { ChevronDown, ChevronLeft, ThreeDotsVertical, SlashCircle } from 'react-bootstrap-icons';
import { Card, ButtonIcon, Pagination } from 'app/components';
import { usePermission } from 'app/permissions';
import OrderItem from '../OrderItem';
import './index.scss';

const UnassignItemsCard = (props) => {
  const {
    orderId,
    orderItems, 
    menuState, 
    toggleMenu,
    showCancelOrderItemModal,
    showCreateShipmentModal
  } = props;

  // get user permissions
  const canUpdateShipments = usePermission('order', 'update');

  const itemOptions = () => [
    { value: 'Create Shipment', label: 'Create Shipment', onClick: () => { showCreateShipmentModal({ orderItems }) } }
  ];

  // separate uncanceled and canceled items
  const uncanceledItems = useMemo(() => orderItems.filter(item => item.status !== 'canceled'), [orderItems]);
  const canceledItems = useMemo(() => orderItems.filter(item => item.status === 'canceled'), [orderItems]);

  const [currentPageUncanceled, setCurrentPageUncanceled] = useState(1);
  const [currentPageCanceled, setCurrentPageCanceled] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  // calculate displayed items for uncanceled and canceled lists
  const displayedUncanceledItems = useMemo(() => {
    const startIndex = (currentPageUncanceled - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return uncanceledItems.slice(startIndex, endIndex);
  }, [uncanceledItems, currentPageUncanceled, pageSize]);

  const displayedCanceledItems = useMemo(() => {
    const startIndex = (currentPageCanceled - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return canceledItems.slice(startIndex, endIndex);
  }, [canceledItems, currentPageCanceled, pageSize]);

  const totalUncanceledItems = uncanceledItems.length;
  const totalCanceledItems = canceledItems.length;

  const allItemsCanceled = orderItems.length > 0 && uncanceledItems.length === 0;

  return (
    <Card className="unassigned-items-card">
      <Card.Header>
        <div className={`unassigned-items-header ${menuState['unassignedItems'] && 'collapsed'} ${allItemsCanceled && 'all-items-canceled'}`}>
          <div className="header-title">
            {allItemsCanceled && <SlashCircle />}
            No Shipment Assigned
          </div>
          <div className="item-options">
            {canUpdateShipments && !allItemsCanceled && (
              <ButtonIcon
                icon={<ThreeDotsVertical />}
                options={itemOptions()}
                darkMode={true}
              />
            )}
            <ButtonIcon
              icon={menuState['unassignedItems'] ? <ChevronLeft /> : <ChevronDown />}
              onClick={() => toggleMenu('unassignedItems')}
              darkMode={true}
            />
          </div>
        </div>
      </Card.Header>
      
      {!menuState['unassignedItems'] && (
        <Card.Body>
          {/* Unassigned Items */}
          {displayedUncanceledItems.map((orderItem) => (
            <OrderItem
              key={orderItem.id}
              orderId={orderId}
              itemInfo={orderItem}
              showCancelOrderItemModal={showCancelOrderItemModal}
            />
          ))}
          {totalUncanceledItems > pageSize && (
            <div className="pagination-container">
              <Pagination
                totalItems={totalUncanceledItems}
                pageSizes={[5, 10, 20, 50]}
                hideBelow={5}
                currentPage={currentPageUncanceled}
                pageSize={pageSize}
                onChange={(page, newPageSize) => {
                  setCurrentPageUncanceled(page);
                  setPageSize(newPageSize);
                }}
              />
            </div>
          )}

          {/* Canceled Items */}
          {canceledItems.length > 0 && (
            <div className="canceled-items-section">
              <div className="canceled-items-top-bar">
              <div className="canceled-items-title"><SlashCircle />Canceled Items</div>
              <ButtonIcon
                icon={menuState['canceledItems'] ? <ChevronDown /> : <ChevronLeft />}
                onClick={() => toggleMenu('canceledItems')}
              />
            </div>
            {menuState['canceledItems'] && (
              <>
                {displayedCanceledItems.map((orderItem) => (
                  <OrderItem
                key={orderItem.id}
                orderId={orderId}
                itemInfo={orderItem}
                showCancelOrderItemModal={showCancelOrderItemModal}
              />
            ))}
            {totalCanceledItems > pageSize && (
              <div className="pagination-container">
                <Pagination
                  totalItems={totalCanceledItems}
                  pageSizes={[5, 10, 20, 50]}
                  hideBelow={5}
                  currentPage={currentPageCanceled}
                  pageSize={pageSize}
                  onChange={(page, newPageSize) => {
                    setCurrentPageCanceled(page);
                    setPageSize(newPageSize);
                  }}
                />
                  </div>
                )}
              </>
              )}
            </div>
          )}
        </Card.Body>
      )}
    </Card>
  );
};

export default UnassignItemsCard;
