import { createAction } from 'redux-actions';

const prefix = "CLIENT";

export const createClient = createAction(`${prefix}/CREATE_CLIENT`);
export const createClientSuccess = createAction(`${prefix}/CREATE_CLIENT_SUCCESS`);
export const createClientFailure = createAction(`${prefix}/CREATE_CLIENT_FAILURE`);

export const getClient = createAction(`${prefix}/GET_CLIENT`);
export const getClientSuccess = createAction(`${prefix}/GET_CLIENT_SUCCESS`);
export const getClientFailure = createAction(`${prefix}/GET_CLIENT_FAILURE`);

export const generateSecret = createAction(`${prefix}/GENERATE_SECRET`);
export const generateSecretSuccess = createAction(`${prefix}/GENERATE_SECRET_SUCCESS`);
export const generateSecretFailure = createAction(`${prefix}/GENERATE_SECRET_FAILURE`);

export const deleteClient = createAction(`${prefix}/DELETE_CLIENT`);
export const deleteClientSuccess = createAction(`${prefix}/DELETE_CLIENT_SUCCESS`);
export const deleteClientFailure = createAction(`${prefix}/DELETE_CLIENT_FAILURE`);

export const toggleGenerateSecret = createAction(`${prefix}/TOGGLE_GENERATE_SECRET`);
export const toggleDeleteClient = createAction(`${prefix}/TOGGLE_DELETE_CLIENT`);

export const cancel = createAction(`${prefix}/CANCEL`);

export const reset = createAction(`${prefix}/RESET`);
