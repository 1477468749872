/* eslint-disable no-unreachable */

import { put, takeEvery, call, all, takeLatest, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  getUserDetails,
  getUserDetailsSuccess,
  getUserDetailsError,
  signinUser,
  signinUserSuccess,
  signinUserError,
  setPermissions,
  getUsers,
  getUsersSuccess,
  getUsersFailure,
  createUser,
  createUserSuccess,
  createUserFailure,
  updateUser,
  updateUserSuccess,
  updateUserFailure,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailure,
  requestForgotPassword,
  requestForgotPasswordSuccess,
  requestForgotPasswordFailure,
  updateForgotPassword,
  updateForgotPasswordSuccess,
  updateForgotPasswordFailure,
  logoutUser,
  getRoles,
  getRolesSuccess,
  getRolesFailure,
  updateUserStatus,
  updateUserStatusFailure,
  updateUserStatusSuccess,
  deleteUser,
  deleteUserSuccess,
  deleteUserFailure,
  getRolesWithPermissions,
  getRolesWithPermissionsSuccess,
  getRolesWithPermissionsError,
  deleteRole,
  deleteRoleFailure,
  deleteRoleSuccess,
  updateRole,
  updateRoleFailure,
  updateRoleSuccess,
  createRole,
  createRoleFailure,
  createRoleSuccess,
  getRoleDetails,
  getRoleDetailsSuccess,
  getRoleDetailsError,
  getResources,
  getResourcesSuccess,
  getResourcesFailure,
  searchRolesSuccess,
  searchRolesFailure,
  searchRoles,
  updateRoleName,
  getGroups,
  getGroupsSuccess,
  getGroupsFailure,
  setViewAs,
  setViewAsSuccess,
  getUserSchemaSuccess,
  getUserSchemaFailure,
  getUserSchema,
  getUserConfigurationSuccess,
  getUserConfigurationFailure,
  getUserConfiguration,
  updateUserConfiguration,
  updateUserConfigurationSuccess
} from "app/store/actions/user";
import { RESET_ALL } from "app/store/actions/user";
import UserServices from "app/services/userServices";
import MerchantService from "app/services/merchantServices";
import VendorService from "app/services/vendorServices";
import ConfigServices, { USER_SCHEMA } from "app/services/configServices";
import { viewAsSelector, currentUserSelector, userDetailsSelector } from "../selectors/user";

function* doSigninUser(action) {
  const { username, password, onSigninSuccess } = action.payload;

  try {
    // make the api call to sign the user in
    const data = yield call(
      [UserServices, UserServices.signinUser],
      username,
      password
    );

    // an invalid username or password will return an array of errors (with a 200 status code)
    if (data.errors) {
      throw new Error(data.errors[0].errorMessage);
    }

    // save their permissions in redux
    const permissions = {};
    data.roles.forEach((role) => {
      role.permissionSet.forEach((permissionSet) => {
        const resource = permissionSet.resourceName;
        if (!permissions[resource]) {
          permissions[resource] = [];
        }
        permissions[resource] = [
          ...new Set([
            ...permissions[resource],
            ...permissionSet.associatedPermissions,
          ]),
        ];
      });
    });
    yield put(setPermissions(permissions));

    // update the redux state with the status
    yield put(signinUserSuccess(data));

    onSigninSuccess();
  } catch (error) {
    toast.error("Invalid Username or Password", {
      theme: 'colored',
    })
    // an error occurred (invalid credentials, api down, etc). Report the error to the user
    yield put(signinUserError(error));
  }
}

function* fetchUserDetails(action) {
  const userId = action.payload;
  try {
    const data = yield call([UserServices, UserServices.getUserById], userId);
    yield put(getUserDetailsSuccess(data));
  } catch (error) {
    console.error("error", error);
    yield put(getUserDetailsError(error));
  }
}

function* fetchUsers(action) {
  const {
    searchString,
    selectedStatus,
    selectedSortBy,
    selectedSortDir,
    currentPage,
    pageSize,
  } = action.payload;

  let apiStatus = "";
  let apiSortBy = "";
  let apiDesc = "";

  // handle the status parameter
  switch (selectedStatus) {
    case "active":
      apiStatus = "enabled";
      break;
    case "inactive":
      apiStatus = "disabled";
      break;
    default:
      apiStatus = "";
  }

  // handle sort...
  switch (selectedSortBy) {
    case "id":
      apiSortBy = "shortId";
      break;
    case "email":
      apiSortBy = selectedSortBy;
      break;
    case "name":
      apiSortBy = "firstName";
      break;
    case "roles":
      apiSortBy = "role";
      break;
    case "createdOn":
      apiSortBy = "dateCreated";
      break;
    default:
      apiSortBy = "";
  }

  // handle sorting direction...
  switch (selectedSortDir) {
    case "asc":
      apiDesc = "false";
      break;
    case "desc":
      apiDesc = "true";
      break;
    default:
      apiDesc = "";
  }

  try {
    const data = yield call(
      [UserServices, UserServices.getUsers],
      searchString,
      apiStatus,
      apiSortBy,
      apiDesc,
      currentPage,
      pageSize
    );
    //data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    yield put(getUsersSuccess(data));
  } catch (error) {
    console.error("error", error);
    yield put(getUsersFailure());
  }
}

function* updateUserAttributesFromEntities(entity, attrs) {
  switch (entity?.value?.[0]) {
    case "p": {
      const parentId = entity?.value?.slice(2);
      const parentMerchant = yield call([MerchantService, MerchantService.getMerchantById], parentId);
      attrs.type = "parent";
      attrs.entity = "merchant";
      attrs.entityId = parentId;
      attrs.associatedIds = parentMerchant?.children.map((org) => org.id);
    }
      break;
    case "m": 
      attrs.type = "";
      attrs.entity = "merchant";
      attrs.entityId = entity?.value?.slice(2);
      attrs.associatedIds = [];
      break;
    case "v": {
      const vendorId = entity?.value?.slice(2);
      const vendor = yield call([VendorService, VendorService.getVendorDetails], vendorId);
      attrs.type = "";
      attrs.entity = "vendor";
      attrs.entityId = vendorId;
      attrs.associatedIds = vendor?.vendor?.facilities.map((org) => org.id);
    }
      break;
    case "f": 
      attrs.type = "";
      attrs.entity = "facility";
      attrs.entityId = entity?.value?.slice(2);
      attrs.associatedIds = [];
      break;
    default:
      break;
  }
}

function createMultiUserAttributesFromEntities(selectedEntities, attrs) {
  attrs.type = "multi";
  attrs.entityId = selectedEntities[0].value?.slice(2);
  
  // check who entities are?...
  const entityPrefix = selectedEntities[0].value[0];
  switch (entityPrefix) {
    case "m":
      attrs.entity = "merchant";
      attrs.associatedIds = selectedEntities.map((org) => org.value?.slice(2));
      break;
    case "f":
      attrs.entity = "facility";
      attrs.associatedIds = selectedEntities.map((org) => org.value?.slice(2));
      break;
    default:
      break;
  }
}

function* createUserAttributesFromEntities(entity, attrs) {
  switch (entity?.value[0]) {
    case "p": {
      const parentId = entity?.value.slice(2);
      const parentMerchant = yield call([MerchantService, MerchantService.getMerchantById], parentId);
      attrs.type = "parent";
      attrs.entity = "merchant";
      attrs.entityId = parentId;
      attrs.associatedIds = parentMerchant?.children.map((org) => org.id);
    }
      break;
    case "m": {
      const merchantId = entity?.value.slice(2);
      attrs.entity = "merchant";
      attrs.entityId = merchantId;
    }
      break;
    case "v": {
      const vendorId = entity?.value.slice(2);
      const vendor = yield call([VendorService, VendorService.getVendorDetails], vendorId);
      attrs.entity = "vendor";
      attrs.entityId = vendorId;
      attrs.associatedIds = vendor?.vendor?.facilities.map((org) => org.id);
    }
      break;
    case "f": {
      const facilityId = entity?.value.slice(2);
      attrs.entity = "facility";
      attrs.entityId = facilityId;
    }
      break;
    default:
      break;
  }
}

function* doCreateUser(action) {
  const { values, cb } = action.payload;
  const viewAs = yield select(viewAsSelector);

  try {
    // create new user...
    const createUserResp = yield call(
      [UserServices, UserServices.createUser],
      values
    );

    // make sure the createUser call was successful
    if (!createUserResp.id) {
      const errMsg = "Create User Failed";
      yield put(createUserFailure(errMsg));
      toast.error(errMsg, {
        theme: "colored",
      });
    }

    // proceed further by creating user attributes...
    let attrs = {
      type: "",
      entity: "",
      entityId: "",
      associatedIds: []
    }

    // check if we use ViewAs...
    if (viewAs) {
      attrs.entityId = viewAs.id;

      switch (viewAs?.userType) {
        case "parent-merchant":
          attrs.type = "parent";
          attrs.entity = "merchant";

          // this means that user didn't select any specific organization to assign to this parent
          // he wants all organizations to be assigned to this parent merchant
          if (values.selectedEntities?.length == 0) {
            // get all organizations for this parrent merchant...
            const parentMerchant = yield call([MerchantService, MerchantService.getMerchantById], viewAs.id);
            attrs.associatedIds = parentMerchant?.children.map((org) => org.id);
          } else {
            // this means that user selected specific organizations to assign to this parent merchant
            attrs.associatedIds = values.selectedEntities.map((org) => org.value?.slice(2));
          }
          break;
        case 'merchant':
          attrs.entity = "merchant";
          break;
        case "vendor":
          attrs.entity = "vendor";

          // this means that user didn't select any specific facility to assign to this vendor
          // he wants all facilities to be assigned
          if (values.selectedEntities?.length == 0) {
            // get all organizations for this vendor...
            const vendor = yield call([VendorService, VendorService.getVendorDetails], viewAs.id);
            attrs.associatedIds = vendor?.vendor?.facilities.map((org) => org.id);
          } else {
            // this means that user selected specific organizations to assign to this vendor
            attrs.associatedIds = values.selectedEntities.map((org) => org.value?.slice(2));
          }
          break;
        case 'facility':
          attrs.entity = "facility";
          break;
        default:
          break;
      }
    } else {
      // no ViewAs data available, so we use other ways to determine user attributes...
      // it means that user selected some organizations to assign to this user...
      if (values.selectedEntities?.length > 0) {
        // single organization selected...
        if (values.selectedEntities.length === 1) {
          yield call(createUserAttributesFromEntities, values.selectedEntities[0], attrs);
        } else {
          // multiple organizations selected, so we need to create multi child user...
          yield call(createMultiUserAttributesFromEntities, values.selectedEntities, attrs);
        }

      } else {
        // organization is not selected, so user will be created with organization from logged in user...
        const user = yield select(currentUserSelector);
        attrs.type = user.attributes.type?.[0];
        attrs.entity = user.attributes.entity?.[0];
        attrs.entityId = user.attributes.entityId?.[0];
        attrs.associatedIds = user.attributes.associatedIds;
      }
    }

    // validate attributes...
    // this is a user without any organization selected, and it's not super-admin
    // so we will assign some default attributes to this user, until user gets updated later...
    // TO-DO check expected behavior for this case...
    attrs.entity = attrs.entity || "empty";
    attrs.entityId = attrs.entityId || "empty";

    yield call(
      [UserServices, UserServices.addAttributesToUser],
      createUserResp.id,
      attrs.type,
      attrs.entity,
      attrs.entityId,
      attrs.associatedIds
    );

    // assign the role to the user
    yield call(
      [UserServices, UserServices.assignRole],
      createUserResp.id,
      values.role
    );

    // assign the permissions to the user (user permission overrides TO-DO)
    // yield call(
    //   [UserServices, UserServices.assignUserPermissions],
    //   createUserResp.id,
    //   values.role,
    //   values.roles.map((role) => ({
    //     resourceId: role.resourceId,
    //     resourceName: values.roleAndPermissions.permissionSet.find(
    //       (p) => p.resourceId === role.resourceId
    //     )?.resourceName,
    //     permissions: role.permissions.map((perm) => perm.name),
    //   }))
    // );

    yield put(createUserSuccess(createUserResp));
    if (cb) cb(createUserResp.id);
    toast.success("New User Successfully Created", {
      theme: "colored",
    });

  } catch (error) {
    console.error("error", error);
    yield put(createUserFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* doUpdateUser(action) {
  const { userId, values, cb } = action.payload;
  try {
    // update user first name, last name and email...
    const resp = yield call(
      [UserServices, UserServices.updateUser],
      userId,
      values
    );

    // this is user details we wish to update...
    const userToUpdate = yield select(userDetailsSelector);

    let attrs = {
      type: userToUpdate.attributes.type?.[0],
      entity: userToUpdate.attributes.entity?.[0],
      entityId: userToUpdate.attributes.entityId?.[0],
      associatedIds: userToUpdate.attributes.associatedIds
    }

    if (values.selectedEntities?.length > 0) {
      if (values.selectedEntities.length === 1) {
        // single organization selected...
        yield call(updateUserAttributesFromEntities, values.selectedEntities[0], attrs);
      } else {
        // multiple organizations selected, so we need to update multi user...
        yield call(createMultiUserAttributesFromEntities, values.selectedEntities, attrs);
      }

      yield call(
        [UserServices, UserServices.addAttributesToUser],
        userId,
        attrs.type,
        attrs.entity,
        attrs.entityId,
        attrs.associatedIds
      );
    }

    //this will be performed only when we change the role
    if (values.initialRole != values.role) {
      if (values.initialRole != "") {
        // unassign the old role from the user
        yield call(
          [UserServices, UserServices.unassignRole],
          userId,
          values.initialRole
        );
      }

      // assign the new role to the user
      yield call([UserServices, UserServices.assignRole], userId, values.role);
    }

    // assign the permissions to the user (user permission overrides TO-DO)
    // yield call(
    //   [UserServices, UserServices.assignUserPermissions],
    //   userId,
    //   values.role,
    //   values.roles.map((role) => ({
    //     resourceId: role.resourceId,
    //     resourceName: values.roleAndPermissions.permissionSet.find(
    //       (p) => p.resourceId === role.resourceId
    //     )?.resourceName,
    //     permissions: role.permissions.map((perm) => perm.name),
    //   }))
    // );

    yield put(updateUserSuccess(resp));
    if (cb) cb(userId);
    toast.success("User Successfully Updated", {
      theme: "colored",
    });
  } catch (error) {
    console.error("error", error);
    yield put(updateUserFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* doResetPassword(action) {
  const { userId, cb } = action.payload;

  try {
    const resp = yield call([UserServices, UserServices.resetPassword], userId);
    yield put(resetPasswordSuccess(resp));
    if (cb) cb(resp);
    toast.success("Password Reset Successful", {
      theme: "colored",
    });
  } catch (error) {
    console.error("error", error);
    yield put(resetPasswordFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* doForgotPassword(action) {
  const { email, isAdmin, cb, failCB } = action.payload;

  try {
    const resp = yield call([UserServices, UserServices.forgotPassword], email);
    yield put(requestForgotPasswordSuccess(resp));
    if (cb) cb(resp);
    if (isAdmin) {
      toast.success(
        `An email with instructions for password reset was sent to ${email}.`,
        {
          theme: "colored",
        }
      );
    } else {
      toast.success(
        "You will receive an email with instructions for password reset.",
        {
          theme: "colored",
        }
      );
    }
  } catch (error) {
    console.error("error", error);
    yield put(requestForgotPasswordFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
    if (failCB) {
      failCB();
    }
  }
}

function* doUpdateForgotPassword(action) {
  const { token, password, cb, failCB } = action.payload;

  try {
    const resp = yield call(
      [UserServices, UserServices.updateForgotPassword],
      token,
      password
    );
    yield put(updateForgotPasswordSuccess(resp));
    if (cb) cb(resp);
    toast.success(
      "You have successfully reset your password. You can login using your new password.",
      {
        theme: "colored",
      }
    );
  } catch (error) {
    console.error("error", error);
    yield put(updateForgotPasswordFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
    if (failCB) {
      failCB();
    }
  }
}

function* doLogoutUser() {
  localStorage.clear();
  yield put({ type: RESET_ALL });
}

function* fetchRoles() {
  try {
    const data = yield call([UserServices, UserServices.getRoles]);
    yield put(getRolesSuccess(data));
  } catch (error) {
    console.error("error", error);
    yield put(getRolesFailure());
  }
}

function* fetchRolesWithPermissions() {
  try {
    const data = yield call([
      UserServices,
      UserServices.getRoleWithPermissions,
    ]);
    if (!data) return [];

    // do a sorting here...
    let sortedData = data
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((role) => ({
        ...role,
        permissionSet: role.permissionSet
          .sort((a, b) => a.resourceName.localeCompare(b.resourceName))
          .map((permission) => ({
            ...permission,
            availablePermissions: permission.availablePermissions.sort(),
          })),
      }));
    yield put(getRolesWithPermissionsSuccess(sortedData));
  } catch (error) {
    console.error("Fetching roles with permissions failed: ", error);
    yield put(getRolesWithPermissionsError(error));
  }
}

function* fetchResources() {
  try {
    const data = yield call([UserServices, UserServices.getResources]);

    yield put(getResourcesSuccess(data));
  } catch (error) {
    yield put(getResourcesFailure(error));
  }
}

function* doCreateRole(action) {
  const { values, cb } = action.payload;

  try {
    // make the api call to create the role
    const createRoleResp = yield call(
      [UserServices, UserServices.createRole],
      values
    );

    // make sure the createRole call was successful
    if (!createRoleResp.id) {
      const errMsg = "Create Role Failed";
      yield put(createRoleFailure(errMsg));
      toast.error(errMsg, {
        theme: "colored",
      });
    } else {
      //add permissions for created role
      if (values.permissions?.length > 0) {
        const payload = values.permissions.map((permission) => ({
          resourceId: permission.resourceId,
          resourceName: values.permissionsSet.find(
            (p) => p.resourceId === permission.resourceId
          )?.resourceName,
          permissions: permission.permissions.map((perm) => perm.name),
        }));
        yield call(
          [UserServices, UserServices.updateRole],
          createRoleResp.id,
          payload
        );
      }

      yield put(createRoleSuccess(createRoleResp));
      if (cb) cb(createRoleResp.id);
      toast.success("New Role Successfully Created", {
        theme: "colored",
      });
    }
  } catch (error) {
    console.error("error", error);
    yield put(createRoleFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* doUpdateRole(action) {
  const { roleId, values, cb } = action.payload;

  try {
    const payload = values.permissions.map((permission) => ({
      resourceId: permission.resourceId,
      resourceName: values.permissionsSet.find(
        (p) => p.resourceId === permission.resourceId
      )?.resourceName,
      permissions: permission.permissions.map((perm) => perm.name),
    }));
    const resp = yield call(
      [UserServices, UserServices.updateRole],
      roleId,
      payload
    );

    yield put(updateRoleSuccess(resp));
    if (cb) cb(roleId);
    toast.success("Role Successfully Updated", {
      theme: "colored",
    });
  } catch (error) {
    console.error("error", error);
    yield put(updateRoleFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* handleUpdateUserStatus(action) {
  const { id, status, cb } = action.payload;

  const enabled = status === "activate";

  try {
    const resp = yield call(
      [UserServices, UserServices.updateUserStatus],
      id,
      enabled
    );
    yield put(updateUserStatusSuccess(resp));
    if (cb) cb();
    toast.success("User Status Successfully Updated", {
      theme: "colored",
    });
  } catch (error) {
    console.error("error", error);
    yield put(updateUserStatusFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* handleDeleteUser(action) {
  const { id, cb } = action.payload;

  try {
    const resp = yield call([UserServices, UserServices.deleteUser], id);
    yield put(deleteUserSuccess(resp));
    if (cb) cb();
    toast.success("User Successfully Deleted", {
      theme: "colored",
    });
  } catch (error) {
    console.error("error", error);
    yield put(deleteUserFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* handleDeleteRole(action) {
  const { id, cb } = action.payload;

  try {
    const resp = yield call([UserServices, UserServices.deleteRole], id);
    yield put(deleteRoleSuccess(resp));
    if (cb) cb();
    toast.success("Role Successfully Deleted", {
      theme: "colored",
    });
  } catch (error) {
    console.error("error", error);
    yield put(deleteRoleFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* fetchRoleDetails(action) {
  const roleId = action.payload;
  try {
    const data = yield call([UserServices, UserServices.getRoleById], roleId);
    yield put(getRoleDetailsSuccess(data));
  } catch (error) {
    console.error("error", error);
    yield put(getRoleDetailsError(error));
  }
}

function* fetchSearchRoles(action) {
  const {
    searchString,
    selectedSortBy,
    selectedSortDir,
    currentPage,
    pageSize,
  } = action.payload;

  let apiSortBy = "";
  let apiDesc = "";

  // handle sort...
  switch (selectedSortBy) {
    case "name":
      apiSortBy = "name";
      break;
    case "userCount":
      apiSortBy = "userCount";
      break;
    default:
      apiSortBy = "";
  }

  // handle sorting direction...
  switch (selectedSortDir) {
    case "asc":
      apiDesc = "false";
      break;
    case "desc":
      apiDesc = "true";
      break;
    default:
      apiDesc = "";
  }

  try {
    const data = yield call(
      [UserServices, UserServices.searchRoles],
      searchString,
      apiSortBy,
      apiDesc,
      currentPage,
      pageSize
    );
    yield put(searchRolesSuccess(data));
  } catch (error) {
    console.error("error", error);
    yield put(searchRolesFailure());
  }
}

function* handleUpdateRoleName(action) {
  const { roleId, name, cb } = action.payload;

  try {
    yield call([UserServices, UserServices.updateRoleName], roleId, name);

    if (cb) cb();
    toast.success("Role Name Successfully Updated", {
      theme: "colored",
    });
  } catch (error) {
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* fetchGroups() {
  try {
    const data = yield call([UserServices, UserServices.getGroups]);
    yield put(getGroupsSuccess(data));
  } catch (error) {
    yield put(getGroupsFailure());
    toast.error("Loading Groups Failed", {
      theme: "colored",
    });
  }
}

function* doSetViewAs(action) {
  const { data, cb } = action.payload;

  try {
    yield put(setViewAsSuccess(data));
    if (cb) cb(data);
  } catch (error) {
    console.error("error", error);
  }
}

function* getSchemaHandler() {
  try {
    const resp = yield call(
      [ConfigServices, ConfigServices.getSchema],
      USER_SCHEMA
    );
    yield put(getUserSchemaSuccess(resp));
  } catch (error) {
    console.error("error", error);
    yield put(getUserSchemaFailure(error));
    toast.error("Failed to fetch schema", {
      theme: "colored",
    });
  }
}

function* getConfigurationHandler(action) {
  try {
    const { userId } = action.payload;
    const resp = yield call(
      [ConfigServices, ConfigServices.getConfiguration],
      userId,
      USER_SCHEMA
    );
    yield put(getUserConfigurationSuccess(resp));
  } catch (error) {
    console.error("error", error);
    yield put(getUserConfigurationFailure(error));
    toast.error("Failed to fetch configuration", {
      theme: "colored",
    });
  }
}

function* updateConfigurationHandler(action) {
  const { data, cb, schemaVersion } = action.payload;
  try {
    yield call([ConfigServices, ConfigServices.updateConfiguration], data);
    yield put(updateUserConfigurationSuccess(data));
    toast.success("Configuration Successfully Updated", {
      theme: "colored",
    });
    if (cb) cb();
  } catch (error) {
    console.error("error", error);

    // if we get an error on update, we have to try to create configuration (workaround for ConfigMS)...
    try {
      if (schemaVersion != data.schemaVersion) {
        data.schemaVersion = schemaVersion;
      }
      yield call([ConfigServices, ConfigServices.createConfiguration], data);
      yield put(updateUserConfigurationSuccess(data));
      toast.success("Configuration Successfully Updated", {
        theme: "colored",
      });
      if (cb) cb();
    } catch (error) {
      console.log("error", error);
      yield put(updateUserConfigurationSuccess(error));
      toast.error("Failed to update configuration", {
        theme: "colored",
      });
    }
  }
}

function* watchData() {
  yield takeEvery(signinUser.toString(), doSigninUser);
  yield takeEvery(getUserDetails.toString(), fetchUserDetails);
  yield takeEvery(getUsers().type, fetchUsers);
  yield takeEvery(searchRoles().type, fetchSearchRoles);
  yield takeEvery(createUser.toString(), doCreateUser);
  yield takeEvery(updateUser.toString(), doUpdateUser);
  yield takeEvery(resetPassword.toString(), doResetPassword);
  yield takeEvery(logoutUser.toString(), doLogoutUser);
  yield takeLatest(getRoles.toString(), fetchRoles);
  yield takeLatest(updateUserStatus.toString(), handleUpdateUserStatus);
  yield takeLatest(deleteUser.toString(), handleDeleteUser);
  yield takeLatest(
    getRolesWithPermissions.toString(),
    fetchRolesWithPermissions
  );
  yield takeLatest(requestForgotPassword.toString(), doForgotPassword);
  yield takeLatest(updateForgotPassword.toString(), doUpdateForgotPassword);
  yield takeLatest(deleteRole.toString(), handleDeleteRole);
  yield takeLatest(getResources.toString(), fetchResources);
  yield takeEvery(createRole.toString(), doCreateRole);
  yield takeEvery(updateRole.toString(), doUpdateRole);
  yield takeEvery(getRoleDetails.toString(), fetchRoleDetails);
  yield takeLatest(updateRoleName.toString(), handleUpdateRoleName);
  yield takeLatest(getGroups.toString(), fetchGroups);
  yield takeLatest(setViewAs.toString(), doSetViewAs);
  yield takeLatest(getUserSchema.toString(), getSchemaHandler);
  yield takeLatest(getUserConfiguration.toString(), getConfigurationHandler);
  yield takeLatest(
    updateUserConfiguration.toString(),
    updateConfigurationHandler
  );
}

export default function* rootSaga() {
  yield all([watchData()]);
}
