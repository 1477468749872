import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { object, string, number, boolean } from 'yup';
import { Input, Dropdown, Button, Card, DataPoint, Checkbox, LoadingAnimation } from 'app/components';
import { getVendorConfig, updateVendorConfig } from 'app/store/actions/vendor';
import PropTypes from 'prop-types';
import './index.scss';

// Validation schema using Yup
const VendorConfigSchema = object().shape({
    FtpEnabled: boolean(),
    FtpHost: string().required('FTP Host is required'),
    FtpPort: number().required('FTP Port is required').min(1, 'Minimum 1').max(65535, 'Maximum 65535'),
    FtpProtocol: string().required('FTP Protocol is required'),
    FtpUsername: string().required('FTP Username is required'),
    FtpPassword: string().required('FTP Password is required'),
    RunIntervalPerHour: number().required('Run Interval Per Hours is required').min(1, 'Minimum 1').max(24, 'Maximum 24'),
    FtpInventoryFileDirectory: string().required('FTP Inventory File Directory is required'),
    FtpInventoryFileName: string().required('FTP Inventory File Name is required'),
    FtpInventoryControlFileName: string().required('FTP Inventory Control File Name is required'),
});

const VendorFtpInventorySync = ({ vendorId }) => {
    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();

    const vendorConfig = useSelector(state => state.vendor.vendorConfig.data.data);
    const vendorConfigLoading = useSelector(state => state.vendor.vendorConfig.loading);

    useEffect(() => {
        if (!vendorConfig) {
            dispatch(getVendorConfig(vendorId));
        }
    }, [vendorConfig, dispatch, vendorId]);

    const onVendorFtpSettingUpdated = () => {
        setEditMode(false);
    }

    return (
        <Card className="vendor-ftp-inventory-sync">
            <Card.Header>
                FTP Inventory Sync
                {!editMode && (
                    <Button
                        variant="primary"
                        size="small"
                        label="Edit Configuration"
                        onClick={() => setEditMode(true)}
                    />
                )}
            </Card.Header>
            <Card.Body>

                {
                    vendorConfigLoading ? <LoadingAnimation /> :
                        editMode ? (
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    FtpEnabled: vendorConfig?.FTPInventorySettings?.FtpEnabled || false,
                                    FtpHost: vendorConfig?.FTPInventorySettings?.FtpHost || '',
                                    FtpPort: vendorConfig?.FTPInventorySettings?.FtpPort || 21,
                                    FtpProtocol: vendorConfig?.FTPInventorySettings?.FtpProtocol || 'SFTP',
                                    FtpUsername: vendorConfig?.FTPInventorySettings?.FtpUsername || '',
                                    FtpPassword: vendorConfig?.FTPInventorySettings?.FtpPassword || '',
                                    RunIntervalPerHour: vendorConfig?.FTPInventorySettings?.RunIntervalPerHour || 8,
                                    FtpInventoryFileDirectory: vendorConfig?.FTPInventorySettings?.FtpInventoryFileDirectory || '/inventory',
                                    FtpInventoryFileName: vendorConfig?.FTPInventorySettings?.FtpInventoryFileName || 'inventory_{{YYYY}}{{MM}}{{DD}}_{{HH}}{{*}}.csv',
                                    FtpInventoryControlFileName: vendorConfig?.FTPInventorySettings?.FtpInventoryControlFileName || 'control_{{YYYY}}{{MM}}{{DD}}_{{HH}}{{*}}.csv'
                                }}
                                validationSchema={VendorConfigSchema}
                                onSubmit={async (values, { setSubmitting }) => {
                                    const data = {
                                        FTPInventorySettings: {
                                            FtpEnabled: values.FtpEnabled,
                                            FtpHost: values.FtpHost,
                                            FtpPort: values.FtpPort,
                                            FtpProtocol: values.FtpProtocol,
                                            FtpUsername: values.FtpUsername,
                                            FtpPassword: values.FtpPassword,
                                            FtpInventoryFileDirectory: values.FtpInventoryFileDirectory,
                                            FtpInventoryFileName: values.FtpInventoryFileName,
                                            FtpInventoryControlFileName: values.FtpInventoryControlFileName,
                                            RunIntervalPerHour: values.RunIntervalPerHour
                                        },
                                    };

                                    dispatch(updateVendorConfig({ vendorId, data, cb: onVendorFtpSettingUpdated }));
                                    setSubmitting(false);

                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    isSubmitting,
                                    submitCount
                                }) => (
                                    <form onSubmit={handleSubmit} className="vendor-information">
                                        <Checkbox
                                            label='Enable FTP'
                                            value={values.FtpEnabled}
                                            size="medium"
                                            checked={values.FtpEnabled}
                                            onChange={(e) => {
                                                setFieldValue('FtpEnabled', e);
                                            }} />
                                        <Input
                                            label="FTP Host"
                                            name="FtpHost"
                                            value={values.FtpHost}
                                            onChange={handleChange}
                                            placeholder="Enter FTP host"
                                            errorMessage={submitCount > 0 && errors.FtpHost}
                                        />
                                        <Input
                                            label="FTP Port"
                                            type="number"
                                            name="FtpPort"
                                            value={values.FtpPort}
                                            onChange={handleChange}
                                            placeholder="Enter FTP port"
                                            errorMessage={submitCount > 0 && errors.FtpPort}
                                        />
                                        <Dropdown
                                            label="FTP Protocol"
                                            name="FtpProtocol"
                                            value={values.FtpProtocol}
                                            onChange={handleChange}
                                            options={[
                                                { value: 'FTP', label: 'FTP' },
                                                { value: 'SFTP', label: 'SFTP' },
                                            ]}
                                            errorMessage={submitCount > 0 && errors.FtpProtocol}
                                        />
                                        <Input
                                            label="FTP Username"
                                            name="FtpUsername"
                                            value={values.FtpUsername}
                                            onChange={handleChange}
                                            placeholder="Enter FTP username"
                                            errorMessage={submitCount > 0 && errors.FtpUsername}
                                        />
                                        <Input
                                            label="FTP Password"
                                            type="password"
                                            name="FtpPassword"
                                            value={values.FtpPassword}
                                            onChange={handleChange}
                                            placeholder="Enter FTP password"
                                            errorMessage={submitCount > 0 && errors.FtpPassword}
                                        />
                                        <Input
                                            label="Run Interval Per Hour"
                                            type="number"
                                            name="RunIntervalPerHour"
                                            value={values.RunIntervalPerHour}
                                            onChange={handleChange}
                                            placeholder="Enter runs per hour (1-24)"
                                            min={1}
                                            max={24}
                                            errorMessage={submitCount > 0 && errors.RunIntervalPerHour}
                                        />
                                        <Input
                                            label="Inventory File Directory"
                                            name="FtpInventoryFileDirectory"
                                            value={values.FtpInventoryFileDirectory}
                                            onChange={handleChange}
                                            placeholder="Enter directory path"
                                            errorMessage={submitCount > 0 && errors.FtpInventoryFileDirectory}
                                        />
                                        <Input
                                            label="Inventory File Name"
                                            name="FtpInventoryFileName"
                                            value={values.FtpInventoryFileName}
                                            onChange={handleChange}
                                            placeholder="e.g., inventory_{{YYYY}}{{MM}}{{DD}}.csv"
                                            errorMessage={submitCount > 0 && errors.FtpInventoryFileName}
                                        />
                                        <Input
                                            label="Control File Name"
                                            name="FtpInventoryControlFileName"
                                            value={values.FtpInventoryControlFileName}
                                            onChange={handleChange}
                                            placeholder="e.g., control_{{YYYY}}{{MM}}{{DD}}.csv"
                                            errorMessage={submitCount > 0 && errors.FtpInventoryControlFileName}
                                        />
                                        <div className="action-buttons">
                                            <Button
                                                variant="secondary"
                                                size="small"
                                                label="Cancel"
                                                onClick={() => setEditMode(false)}
                                            />
                                            <Button
                                                variant="primary"
                                                size="small"
                                                label="Save"
                                                onClick={() => (isSubmitting ? null : handleSubmit())}
                                            />
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        ) : (
                            <div className="vendor-information">
                                <DataPoint title="FTP Enabled" data={vendorConfig?.FTPInventorySettings?.FtpEnabled ? 'Yes' : 'No'} />
                                <DataPoint title="FTP Host" data={vendorConfig?.FTPInventorySettings?.FtpHost} />
                                <DataPoint title="FTP Port" data={vendorConfig?.FTPInventorySettings?.FtpPort} />
                                <DataPoint title="FTP Protocol" data={vendorConfig?.FTPInventorySettings?.FtpProtocol} />
                                <DataPoint title="FTP Username" data={vendorConfig?.FTPInventorySettings?.FtpUsername} />
                                <DataPoint title="FTP Password" data={vendorConfig?.FTPInventorySettings?.FtpPassword} maskData={true} />
                                <DataPoint title="Run Intervals Per Hour" data={vendorConfig?.FTPInventorySettings?.RunIntervalPerHour} />
                                <DataPoint title="FTP Inventory File Directory" data={vendorConfig?.FTPInventorySettings?.FtpInventoryFileDirectory} />
                                <DataPoint title="FTP Inventory File Name" data={vendorConfig?.FTPInventorySettings?.FtpInventoryFileName} />
                                <DataPoint title="FTP Inventory Control File Name" data={vendorConfig?.FTPInventorySettings?.FtpInventoryControlFileName} />
                            </div>
                        )}
            </Card.Body>
        </Card>
    );
};

VendorFtpInventorySync.propTypes = {
    vendorId: PropTypes.string.isRequired,
};
export default VendorFtpInventorySync;
