import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { merchantsSelector, merchantsLoadingSelector, merchantsErrorsSelector } from 'app/store/selectors/merchant';
import { getMerchants, reset as merchantReset } from 'app/store/actions/merchant';
import { reset as routingReset } from 'app/store/actions/routing';
import { Link, Card, Table, TableHeader, Pagination, LoadingAnimation, SearchBar } from 'app/components';
import { ExclamationCircle } from 'react-bootstrap-icons';
import './index.scss';

const RoutingView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [searchString, setSearchString] = useState(null);
  // const [isFilterMenuOpen, showHideFilterMenu] = useState(false);
  const merchantsData = useSelector(merchantsSelector);
  const merchantsLoading = useSelector(merchantsLoadingSelector);
  const merchantsErrorsFound = useSelector(merchantsErrorsSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);
  const searchString = searchParams.get('searchString') || null;
  const sortBy = searchParams.get('sortBy') || 'shortId';
  const sortDir = searchParams.get('sortDir') || 'desc';
  const statusFilter = searchParams.get('status') || null;

  useEffect(() => {
    if (!searchString || searchString.length !== 1) {
      dispatch(getMerchants({
        searchString,
        currentPage,
        pageSize,
        sortBy,
        sortDir
      }));
    }
  }, [searchString, statusFilter, currentPage, pageSize, sortBy, sortDir, dispatch]);

  const onSearchStringUpdated = (searchString) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('page', 1);
    searchString ? updatedSearchParams.set('searchString', searchString) : updatedSearchParams.delete('searchString');
    setSearchParams(updatedSearchParams.toString());
  };

  return (
    <div className="routing-view">
      {merchantsLoading && <LoadingAnimation />}
      {(merchantsData || merchantsErrorsFound) && (
        <Card>
          <div className="routing-header">Routing</div>
          <SearchBar
            searchPlaceholderText="Search for a Merchant"
            onSearchStringUpdated={onSearchStringUpdated}
            debounceDelay={500}
            disabled={merchantsErrorsFound}
          />
          <Table className="merchant-table mb-3" size="medium">
            <TableHeader
              options={[
                { id: 'id', label: 'ID'},
                { id: 'merchantName', label: 'Merchant Name'},
                { id: 'activeStrategy', label: 'Active Strategy'},
              ]}
            />
            <tbody className="table-body">
              {merchantsData && merchantsData.merchants.map((merchant) => (
                  <tr className="merchant-row" key={merchant.id}>
                    <td>
                      <Link
                        label={merchant.shortId || merchant.id}
                        url={`/admin/routing/${merchant.id}`}
                        onClick={() => {
                          dispatch(merchantReset());
                          dispatch(routingReset());
                          navigate(`/admin/routing/${merchant.id}`);
                        }}
                      />
                    </td>
                    <td>{merchant.name}</td>
                    <td>{merchant.activeStrategy}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {merchantsData?.merchants?.length === 0 && (
            <div className="no-matching-results">No Results Found</div>
          )}
          {merchantsData && (
            <Pagination
              totalItems={merchantsData?.total || 0}
              hideBelow={30}
            />
          )}
          {merchantsErrorsFound && (
            <div className="routing-load-failed"><ExclamationCircle />Merchant Data failed to load.  Refresh the page to try again.</div>
          )}
        </Card>
      )}
    </div>
  )
}

export default RoutingView;